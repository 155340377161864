import { Component } from '@angular/core';

import { TealiumUtagService } from '../tealium/utag.service';

@Component({
  selector: 'stars-guide-page',
  templateUrl: './guide.html',
  styleUrls: ['./guide.scss'],
  providers: [TealiumUtagService]
})
export class GuidePageComponent {}
