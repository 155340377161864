import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'stars-notfound-page',
  templateUrl: './notfound.html',
  styleUrls: ['./notfound.scss']
})
export class NotFoundPageComponent {
  constructor(private location: Location) {}

  goBack() {
    this.location.back();

    return false;
  }
}
