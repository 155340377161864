<div class="footer-top">
  <div class="main">
    <img src="../../assets/img/army_tagline1_gold_rgb_300ppi.png" alt="Be All You Can Be" class="army-tagline">
    <p>Learn More<span>>></span> <a href="https://www.goarmy.com/?iom=AFOD-2675107_STARS_Website" class="goArmyLink" target="_blank">goarmy.com</a></p>
  </div>

  <div class="army-logo-container">
    <a href="https://www.goarmy.com/?iom=AFOD-2675107_STARS_Website" class="army-logo goArmyLink" target="_blank">
      <img src="../../assets//img/army_logo2_rev_rgb.svg">
    </a>
  </div>
</div>

<div class="footer-bottom">
  <div class="copyright">
    <p>&copy; {{ copyright }} U.S. Army All Rights Reserved.</p>
    <a href="/legal/terms">Terms &amp; Conditions</a>
    <a href="/legal/privacy">Privacy Policy</a>
    <a href="mailto:support@armygamestudio.com?subject=Support%20Request%20from%20Stars%20Website">Email Support</a>
  </div>
</div>
