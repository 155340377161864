<div class="dialog-content">
  <div class="close" (click)="theodoreRexDialogRef.close()"></div>

  <div class="external-wrapper" (click)="theodoreRexDialogRef.close()"></div>

  <video controls poster="/assets/img/anatomy/tutorial-trex.png">
    <source src="/assets/videos/tutorial-trex.mp4" type="video/mp4">
    <img src="/assets/img/anatomy/tutorial-trex.png">
    Sorry, your browser doesn't support embedded videos.
  </video>
</div>
