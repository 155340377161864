import { Component } from '@angular/core';

import { TealiumUtagService } from '../tealium/utag.service';

@Component({
  selector: 'stars-terms-page',
  templateUrl: './terms.html',
  styleUrls: ['./terms.scss'],
  providers: [TealiumUtagService]
})
export class TermsComponent {}
