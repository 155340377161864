import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, Event } from '@angular/router';

@Component({
  selector: 'stars-topps-trikala-dialog',
  templateUrl: './topps-trikala-dialog.component.html',
  styleUrls: ['./topps-trikala-dialog.component.scss']
})
export class ToppsTrikalaDialogComponent implements OnInit, OnDestroy {
  constructor(
    public toppsTrikalaDialogRef: MatDialogRef<ToppsTrikalaDialogComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      this.toppsTrikalaDialogRef.close();
    });
  }

  ngOnDestroy(): void {
    if (this.toppsTrikalaDialogRef && this.toppsTrikalaDialogRef.close) {
      this.toppsTrikalaDialogRef.close();
    }
  }
}
