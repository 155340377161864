<header [class.open]="menuOpen$ | async">
  <div class="stars-logo">
    <a [href]="['']" routerLinkActive="router-link-active">
      <img src="../../assets/img/stars-logo.svg">
    </a>
  </div>

  <em id="mobile-menu-button" class="hamburger" (click)="onToggleMenu()">
    <span class="hamburger-inner"></span>
  </em>
  <stars-menu (toggle)="onToggleMenu()" [open]="menuOpen$ | async"></stars-menu>

  <div class="army-logo">
    <a (click)="tealiumLink($event)" angulartics2On="click" angularticsCategory="Outbound Clicks"
      angularticsEvent="Header Logo Image" [angularticsProperties]="{ label: 'Go Army' }"
      href="https://www.goarmy.com/?iom=AFOD-2675107_STARS_Website" class="goArmyLink" target="_blank">
      <img src="../../assets/img/army_logo2_rev_rgb.svg" alt="U.S. Army" />
    </a>
  </div>
</header>
