<div class="carousel">
  <section>
    <article *ngFor="let image of images; let idx = index; let size = count" #slide
      [ngClass]="{
        'hidden': (size + (this.currentSlideIndex - 1)) % size !== idx || idx !== this.currentSlideIndex,
        'previous': (size + (this.currentSlideIndex - 1)) % size === idx,
        'current': idx === this.currentSlideIndex
      }"
    >
      <h2><a href="{{ image.careerLink }}" class="goArmyLink" target="_blank">{{ image.title }}</a></h2>
      <a class="category-link goArmyLink" href="{{ image.categoryLink }}" target="_blank"><span class="pill-btn-small">{{ image.category }}</span></a>
      <img [src]="image.url">
    </article>
  </section>
</div>
<div class="breadcrumb-container">
  <div *ngFor="let image of images; let idx = index" class="breadcrumb" [ngClass]="{'current': this.currentSlideIndex === idx}" (click)="goToSlide(idx)"></div>
</div>
