import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'stars-contact',
  templateUrl: './contact.html',
  styleUrls: ['./contact.scss']
})
export class ContactPageComponent {
  contactForm: UntypedFormGroup;
  submitted = false;

  validationMessages = {
    emailAddress: {
      required: 'Email address is required',
      email: 'Email is invalid'
    },
    product: {
      required: 'A product is required'
    },
    message: {
      required: 'A message is required'
    }
  };

  constructor(private formBuilder: UntypedFormBuilder) {
    this.buildContactForm();
  }

  buildContactForm(): void {
    this.contactForm = this.formBuilder.group({
      emailAddress: [
        '',
        Validators.compose([Validators.required, Validators.email])
      ],
      product: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  /**
   * Determines if the control has an error
   * @param key
   * @param group
   */
  hasError(key: string, group?: string): boolean {
    const field = group ? `${group}.${key}` : key;
    const control = this.contactForm.get(field);

    if (!control) {
      return false;
    }

    if (
      (!control.valid && control.touched) ||
      (control.untouched && this.submitted)
    ) {
      return control.errors ? true : false;
    }
  }

  /**
   * Retrieves the correct error message for each control
   * @param key
   * @param types
   */
  getErrorMessage(key: string, types: Array<string>, group?: string): object {
    const field = group ? `${group}.${key}` : key;
    const control = this.contactForm.get(field);

    if (this.hasError(key, group)) {
      for (let i = 0; i < types.length; i++) {
        if (control.errors[types[i]]) {
          return this.validationMessages[key][types[i]];
        }
      }
    }
  }

  onSubmit(): void {
    this.submitted = true;

    const { value, valid, touched } = this.contactForm;
    if (touched && valid) {
      console.log(value);
      window.location.href =
        'mailto:support@armygamestudio.com?subject=' +
        value.product +
        '&body=Email: ' +
        value.emailAddress +
        '%0d%0aMessage: ' +
        value.message;
    }
  }
}
