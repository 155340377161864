import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, Event } from '@angular/router';

@Component({
  selector: 'stars-elements-character-modal',
  templateUrl: './elements-character-modal.component.html',
  styleUrls: ['./elements-character-modal.component.scss']
})
export class ElementsCharacterModalComponent implements OnInit, OnDestroy {
  name: string;
  image: string;

  constructor(
    public elementsCharacterDialogRef: MatDialogRef<ElementsCharacterModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.name = data.name;
    this.image = data.image;
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      this.elementsCharacterDialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.elementsCharacterDialogRef.close();
  }
}
