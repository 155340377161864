<img src="/assets/img/elements/elements-character.png" class="mobile-character" alt="U.S. Army STARS Elements Character">

<div class="wrapper">
  <img src="/assets/img/elements-logo.png" alt="U.S. Army STARS Elements Logo">

  <h2>Where Chemistry Comes to Life</h2>

  <div class="store-buttons">
    <a href="https://itunes.apple.com/us/app/u.s.-army-stars-elements-fun/id1103901837?ls=1&mt=8" target="_blank" class="app-store-button">
      <img src="/assets/img/apple-store-badge.svg" alt="Apple Store">

      <div class="hover-border"></div>
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.agsgamestudio.stars" target="_blank" class="app-store-button">
      <img src="/assets/img/google-play.svg" alt="Google Play Store">

      <div class="hover-border"></div>
    </a>
  </div>
</div>
