import { Component } from '@angular/core';

import { TealiumUtagService } from '../tealium/utag.service';

@Component({
  selector: 'stars-privacy-page',
  templateUrl: './privacy.html',
  styleUrls: ['./privacy.scss'],
  providers: [TealiumUtagService]
})
export class PrivacyComponent {}
