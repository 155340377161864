<div class="content">
    <aside>
      <h3>STARS: <em>Anatomy</em> Guide:</h3>
      <a>Overview</a>

      <ul>
        <li>
          <a (click)="scrollToSection('start-screen')">Start Screen</a>
        </li>

        <li>
          <a (click)="scrollToSection('quest')">Quest</a>
        </li>

        <li>
          <a (click)="scrollToSection('tinysaurs')">How to Play Tinysaurs</a>
        </li>

        <li>
          <a (click)="scrollToSection('explore')">Explore</a>
        </li>

        <li>
          <a (click)="scrollToSection('challenge')">Challenge</a>
        </li>

        <li>
          <a (click)="scrollToSection('bone-hunter')">Bone Hunter</a>
        </li>
      </ul>

      <section class="promotion-area">
        <div>
          <img alt="" src="/assets/img/march2success.png">

          <h2>March 2 Success</h2>

          <p>March 2 Success offers educators, parents, mentors and students a free program to help children meet their educational goals. Whether a student is preparing for college, military or entering the civilian workforce, test scores can play an important role in their future.&nbsp;March 2 Success provides access to online study materials to help improve scores on standardized tests such as state exit exams, college entrance exams, military entrance exam (ASVAB) and others.</p>

          <p>Visit <a href="https://www.march2success.com/">March 2 Success</a> for more information.</p>
        </div>
      </section>
    </aside>

    <article class="guide-area">
      <header id="overview">
        <h1>Overview</h1>

        <h2>STARS: <em>Anatomy</em> is a free educational application developed by The Army Game Studio at Systems Simulation, Software, and Integration (S3I) in collaboration with U.S. Army STEM experts and educators. STARS: <em>Anatomy</em> is yet another in a series of leading Army STEM innovation products and programs.</h2>

        <article class="button-row">
          <button (click)="scrollToSection('start-screen')">Start Screen</button> <button (click)="scrollToSection('quest')">Quest</button> <button (click)="scrollToSection('tinysaurs')">Play Tinysaurs</button> <button (click)="scrollToSection('explore')">Explore</button> <button (click)="scrollToSection('challenge')">Challenge</button> <button (click)="scrollToSection('bone-hunter')">Bone Hunter</button>
        </article>
      </header>

      <section>
        <h2 id="start-screen">Start Screen</h2>
        <img alt="Start Screen" src="/assets/img/guide/anatomy/anatomy-start-screen.png">

        <p>The STARS: Anatomy app contains four main areas to interact with:</p>

        <ul>
          <li><strong>Quest</strong> to play the Tinysaurs game to collect various bones</li>

          <li><strong>Explore</strong> the 3D Model of the human skeleton</li>

          <li><strong>Challenge</strong> questions on the human skeleton</li>

          <li><strong>Bone Hunter</strong> tests knowledge on bone location</li>
        </ul>

        <p>In addition, there is a corresponding website <a href="https://www.usarmystars.com/" target="_blank">https://www.usarmystars.com/</a>, which includes teacher resources and an Army STEM page.</p>

        <h3>Quest</h3>

        <p>The Quest game is an interactive game using Tinysaurs to collect bones. The student is challenged to launch a Tinysaur that unlocks each bone and advance to the next level to unlock more bones. There are quizzes every five levels to unlock the next five levels for a total of 30 levels. Each gated quiz relates questions for the bones the student has collected on the previous five levels. The goal each level is to unlock all three gold skulls for a maximum reward, although to progress through the levels the only requirement is collecting all the bones in each level.</p>

        <h3>Interactive Explore 3D Skeleton</h3>

        <p>STARS: Anatomy provides a fully interactive 3-Dimensional model of the human skeleton. The initial view identifies the axial and appendicular groupings of the skeleton. To view more information about the axial skeleton, select the information icon <img alt="icon" class="icon-image" src="/assets/img/guide/anatomy/icon.png">. The informational box displays the pronunciation, shape classification for individual bones, bone facts, and related Army facts/Army professions.</p>

        <p>Search for a specific bone with the search button in the upper right corner, zoom and select a particular bone, or select the menu button under the search icon to view bone groups. Select the information icon <img alt="icon" class="icon-image" src="/assets/img/guide/anatomy/icon.png"> to view more related bone information and even hear the correct pronunciation of the bone by selecting the speaker icon.</p>

        <h3>The STARS Challenge</h3>

        <p>The STARS Challenge allows students to test themselves on what they’ve learned from using the STARS: Anatomy app. In this collection of challenges, students are presented with questions on the three categories of knowledge referenced in the app (Bone Identification, Shape Classification, and multiple-choice bone fact).</p>

        <h3>Bone Hunter</h3>

        <p>The ability to identify bones on the human skeleton can be very difficult. The Bone Hunter lets you practice how well you can identify bones in certain areas or multiple groupings that include the Arms, Hands, Legs, Feet, Cranium, and Torso. Challenge others to get the best streak and identify all the bones of the human skeleton.</p>
      </section>

      <section id="quest">
        <h2>Quest</h2>

        <p>The Quest objective is to allow students to identify different bones in the body and understand how they are grouped. The student is challenged to launch a Tinysaur and avoid obstacles to collect the identified bones. At the beginning of the level, the bones to collect will be displayed and labeled. The student pulls back the flunger and determines the correct arc to avoid the terrain and unlock the bones.</p>

        <img alt="Quest" src="/assets/img/guide/anatomy/quest-screen.png">

        <p>To move on to the next level, the student must collect all required bones for that level. To achieve the maximum of three skulls, the student must collect the required bones and the hidden bonus items using as few Tinysaurs as possible (check the Point System table to view a list of items).</p>

        <p>In the game, bonus objectives allow students to earn extra points for unlocking hard to reach items. Bonus objectives have several purposes:</p>

        <ul>
          <li>Provide the student with a challenge as a meta-goal in addition to passing the level</li>

          <li>Create a deeper strategy through extra points to create competition among peers</li>

          <li>Engages student in the Quest to progress through levels and learn more about specific bones and their groupings</li>
        </ul>

        <p>Students earn points by unlocking the bones for each level and other related items. The table below displays the object, the amount of points awarded, and how to acquire each item.</p>

        <h3>Tinysaurs Point System</h3>

        <div class="table-wrapper">
          <table>
            <tbody>
              <tr>
                <th>Object</th>

                <th>Points Worth</th>

                <th>How to Acquire</th>
              </tr>

              <tr>
                <td>Bones</td>

                <td>5000</td>

                <td>Touch</td>
              </tr>

              <tr>
                <td>Bonus (Amber, Carabiner, Rope)</td>

                <td>5000</td>

                <td>Touch</td>
              </tr>

              <tr>
                <td>Bonus (Backpack, Headlamp, Lantern, Map, Pickaxe)</td>

                <td>8000</td>

                <td>Touch</td>
              </tr>

              <tr>
                <td>Bonus Gems</td>

                <td>750 / 1000</td>

                <td>Break / Touch</td>
              </tr>

              <tr>
                <td>Conglomerate Rocks</td>

                <td>300</td>

                <td>Break (Splits into small and medium rocks)</td>
              </tr>

              <tr>
                <td>Dynamite</td>

                <td>100</td>

                <td>Break</td>
              </tr>

              <tr>
                <td>Large Rocks</td>

                <td>500</td>

                <td>Crack twice and break</td>
              </tr>

              <tr>
                <td>Large Sandblocks</td>

                <td>100</td>

                <td>Break (Splits into small and medium sandblocks)</td>
              </tr>

              <tr>
                <td>Medium Rocks</td>

                <td>200</td>

                <td>Crack</td>
              </tr>

              <tr>
                <td>Medium Sandblocks</td>

                <td>100</td>

                <td>Breaks into small and medium sandblocks</td>
              </tr>

              <tr>
                <td>Small Rocks</td>

                <td>100</td>

                <td>Break</td>
              </tr>

              <tr>
                <td>Small Sandblocks</td>

                <td>100</td>

                <td>Break</td>
              </tr>

              <tr>
                <td>Stalactites</td>

                <td>100</td>

                <td>Loosen and break</td>
              </tr>

              <tr>
                <td>Tinysaurs</td>

                <td>5000</td>

                <td>Complete level with extra tinysaurs</td>
              </tr>
            </tbody>
          </table>

          <h3>Level Progression</h3>

          <img alt="Quest" src="/assets/img/guide/anatomy/level-progression.png">

          <p>To move through the locked gate after five levels, a student is required to collect a specific number of skulls to unlock the gate. For example, to unlock the first gate after level 5, a student must collect 15 skulls and collect 32 skulls then to unlock the gate after level 10. The number of skulls a student has accumulated displays in the top right corner of the Map view in the Quest area. To collect skulls, a student can do any combination of the following:</p>

          <h4>Play a level</h4>

          <p>To get the maximum of 3 skulls, a student must collect all the required bones and any special bonus items. This challenges the student to not only collect required bones but also look for bonus items.</p>

          <p class="image-row"><img alt="Quest" src="/assets/img/guide/anatomy/play-level.png"></p>

          <h4>Explore Activity - Identify the Bone</h4>

          <p>There are five Identify the Bone type questions where the student identifies the highlighted bone and receives a skull for each correct answer. There is a maximum reward of 5 skulls for getting all five questions correct per session.</p>

          <p class="image-row"><img alt="Quest" src="/assets/img/guide/anatomy/explore-activity.png"></p>

          <h4>Explore Activity - Find the Bone</h4>

          <p>A student is given five choices to identify the correct bone. For this example, the student is asked to, “Select the Tibia”. The student receives one skull for every correct answer for a maximum of 5 skulls per session.</p>

          <p class="image-row"><img alt="Quest" src="/assets/img/guide/anatomy/find-bone.png"></p>

          <h4>Challenge Activity - Quiz Quest</h4>

          <p>A five-question quiz allows a student to earn up to a maximum of 5 skulls per session. Each question answered correctly earns the student a golden skull. The quiz questions contain multiple-choice and bone identification type questions within a designated area of the skeletal system that corresponds with previous levels in the Quest.</p>

          <p class="image-row"><img alt="Quest" src="/assets/img/guide/anatomy/quiz-quest.png"></p>

          <h3 id="tinysaurs">How to Play Tinysaurs</h3>

          <p class="image-row skills no-bottom-margin"><img  (click)="launchSkillsVideo('t-rex')" class="tinysaur-skill" alt="tinysaur skill" src="/assets/img/guide/anatomy/tinysaur-skill-1.png"><img  (click)="launchSkillsVideo('triceratops')" class="tinysaur-skill" alt="tinysaur skill" src="/assets/img/guide/anatomy/tinysaur-skill-2.png"></p>

          <p class="image-row skills no-bottom-margin"><img  (click)="launchSkillsVideo('ankylosaurus')" class="tinysaur-skill" alt="tinysaur skill" src="/assets/img/guide/anatomy/tinysaur-skill-3.png"><img  (click)="launchSkillsVideo('stegosaurus')" class="tinysaur-skill" alt="tinysaur skill" src="/assets/img/guide/anatomy/tinysaur-skill-4.png"></p>

          <p>To launch a Tinysaur:</p>

          <ol>
            <li>Tap and hold to grab the handle of the flunger.</li>

            <li>A dotted line appears: this is the path your Tinysaur flies when you let go.</li>

            <li>Let go to launch.</li>

            <li>Some Tinysaurs have special powers, which can be activated with a tap!</li>
          </ol>

          <h3>Meet the Tinysaurs</h3>

          <p class="tinysaurs image-row"><img src="/assets/img/anatomy/terri-tinysaur.png" alt="prof. terri"><span><strong>Professor Terri</strong> is a renowned Professor of Human Archaeology and the lead of this expedition. Though not directly involved with the excavations, Prof. Terri can often be seen hovering about, keeping watch on the team’s progress.</span>
          </p>

          <p class="tinysaurs image-row"><img src="/assets/img/anatomy/red-tinysaur.png" alt="Theodore Rex"><span><strong>Theodore Rex (AKA T-Rex)</strong> is a student intern who’s always eager to get right into the action to learn about bones. T-Rex puts that headstrong nature to good use by ramming headfirst through obstacles in the pursuit of knowledge. T-Rex is first introduced in Level 1.</span>
          </p>

          <p class="tinysaurs image-row"><img src="/assets/img/anatomy/blue-tinysaur.png" alt="Sarah Topps"><span><strong>Sarah “Blue” Tops</strong> is a geologist who approaches problems with precision and strong direction. Blue’s special charge ability gives an extra forward burst of speed and force that helps with tackling specific areas of interest. First introduced in Level 6, Blue’s charge ability is activated on tap.</span>
          </p>

          <p class="tinysaurs image-row"><img src="/assets/img/anatomy/orange-tinysaur.png" alt="Anky Bean"><span><strong>Anky Bean</strong> is an engineer who’s always spinning gears in both jokes and mechanics. Originally conceived as a fun party trick, Anky’s unique spin power keeps the expedition rolling by clearing out obstacles and draw in interesting finds within its radius. First introduced in Level 11, Anky’s spin power is activated on tap.</span>
          </p>

          <p class="tinysaurs image-row"><img src="/assets/img/anatomy/green-tinysaur.png" alt="Plato Stego"><span><strong>Plato Stego</strong> is a demolitions expert who comes from a long line of prominent tinysaurs. Armed with pedigree, Plato uses the Stego trademark “stick ‘n’ explode” plates to clear out obstacles or displace objects for easier excavation. First introduced in Level 16, Plato can stick to most terrain and objects on first contact (excluding slippery terrain, mushrooms, and water) and explode on tap.</span>
          </p>
        </div>
      </section>

      <section id="explore">
        <h2>Explore</h2>

        <img alt="Explore" src="/assets/img/guide/anatomy/explore-skeleton-screen.png">

        <p>The initial view of the skeleton displays the appendicular skeleton and the axial skeleton. The menu button in the upper right allows you to navigate. The X button allows you to return to the Main Menu and the back arrow allows you to go to the previous bone or bone grouping. The top title displays the grouping of the labels displayed. In this example, the skeletal system is divided into the two labeled parts of the appendicular skeleton and the axial skeleton. The search button allows you to search for a specific bone or any grouping of bones. Select the search icon and begin typing the name of the bone. Then the app zooms in on the bone with the label for the bone displayed. For example, select the search icon and type “hamate”. Then select “Hamate (left)” and the image of the Hamate bone of the left hand is displayed.</p>

        <p class="image-row no-bottom-margin"><img alt="Explore" src="/assets/img/guide/anatomy/explore-hand.png"></p>

        <p>To view the bone groupings, select the menu button and the bones for that grouping is displayed. For instance, after the Hamate bone is searched and the menu button selected, all the Carpal bones display in a list view.</p>

        <img alt="Explore" src="/assets/img/guide/anatomy/explore-skeleton-system.png">

        <p>To hide the labels on the Explore app, select the Label button <img alt="icon" class="icon-image" src="/assets/img/guide/anatomy/label-button.png"> in the bottom right corner. The axial skeleton and the appendicular skeleton label disappear. This functionality can be used to quiz yourself on identifying specific bones.</p>

        <h3>Bone Informational Box</h3>

        <img alt="Explore" src="/assets/img/guide/anatomy/bone-information-box.png">

        <p>The name of the bone or grouping of bones is listed at the top of the informational box.</p>

        <p>The second line displays the speaker icon that gives the proper pronunciation of the bone or grouping of bones, the phonetical spelling of the bone, and the shape classification of the bone. To hear the pronunciation of the bone, select the speaker icon <img alt="icon" class="icon-image" src="/assets/img/guide/anatomy/speaker-icon.png">. The speaker turns from blue to yellow <img alt="icon" class="icon-image" src="/assets/img/guide/anatomy/yellow-speaker-icon.png"> when the bone’s pronunciation is spoken.</p>

        <p>The third section displays bone facts or information related to the bone or bone groupings. Read these bone facts to help with mastering the Challenge portion of the app. A lot of the Challenge questions are found in the bone fact portion of the Explore area.</p>

        <p>The area at the bottom displays Army facts related to the skeletal system or Army related professions in the Army medical field. To learn more about the Army related profession or Army innovation, select anywhere in the yellow portion of the Bone Informational box.</p>

        <h3>Augmented Reality</h3>

        <img alt="Explore" src="/assets/img/guide/anatomy/augmented-reality-screen.png">

        <p>NOTE: The augmented reality (AR) can only be viewed on a mobile device. The AR functionality requires a Galaxy S7 or higher for Android devices and an iPhone 6S or higher for IOS devices.</p>

        <p>To view the Augmented Reality of the 3D human skeleton, select the AR button in the bottom left corner of the Explore landing page. Move your mobile device up and down over a stationary item. The 3D model of the human skeleton displays with labels. Walk around the skeleton for a closer look at each bone. In this example, the label identifies the Body of the Sternum.</p>

        <p class="image-row"><img alt="Explore" src="/assets/img/guide/anatomy/skeleton-expanded.png"><img alt="Explore" src="/assets/img/guide/anatomy/skeleton-cropped.png">
        </p>
      </section>

      <section id="challenge">
        <h2>Challenge</h2>

        <img alt="Challenge" src="/assets/img/guide/anatomy/anatomy-challenge-screen.png">

        <p>The goal of the STARS: Anatomy Challenge is to receive 5 stars by successfully answering 10 challenge questions. Each question correctly answered is worth a half-star. Scores are persistent on a per-session basis. Do well enough on the 10 questions and you’ll get a bonus question to receive more stars. There are 3 types of questions presented that are randomized in each area of the skeletal system. The Master Challenge consists of 20 questions from each four areas of the skeletal system groupings: Arms & Hands, Cranium & Spine, Torso, and Legs & Feet.</p>

        <h3>Multiple Choice</h3>

        <p>The multiple-choice questions are a great way to determine whether students have learned the bone facts that are present in the Explore portion of the STARS: Anatomy app.</p>

        <p>The student is presented with a question that displays the relevant bones and they must choose the correct answer from the four options.</p>

        <p class="image-row"><img alt="Challenge" src="/assets/img/guide/anatomy/multiple-choice.png"></p>

        <h3>Bone Identification</h3>

        <p>The Bone Identification type of questions relay how well the students know a bone from a visual depiction from the image. The student is presented with 3 to 5 bones that are highlighted in the image and must match the name of the bone to the visual depiction in the image.</p>

        <p>A student’s ability to identify bones is the basis to understanding the skeletal system. Students can learn all the 206 human bones in the Explore portion of the STARS: Anatomy app.</p>

        <p class="image-row"><img alt="Challenge" src="/assets/img/guide/anatomy/bone-identification.png"></p>

        <h3>Shape Classification</h3>

        <p>A Shape Classification question is a great way to check students’ ability to decipher whether a bone is flat, short, irregular, or long.</p>

        <p>This type of question assesses whether students understand bone shape classification. These shape classifications are displayed in the bone informational box in the Explore portion of the STARS: Anatomy app.</p>

        <p class="image-row"><img alt="Challenge" src="/assets/img/guide/anatomy/shape-classification.png"></p>
      </section>

      <section id="bone-hunter">
        <h2>Bone Hunter</h2>

        <img alt="Bone Hunter" src="/assets/img/guide/anatomy/bonehunter-pubis.png">

        <p>Chart your Bone Streak towards identifying all the bones in the human skeleton. Select the bone, which illuminates your selected bone as orange. To confirm your selection, select Submit.</p>

        <h3>Incorrect Response</h3>

        <p>If you answer incorrectly, the bone illuminates red and displays the name of the incorrect bone. In this example, Ischium was selected and orange circle narrows the search to the correct bone. You may try again or use the double arrow <img class="icon-image" alt="arrow button" src="/assets/img/guide/anatomy/next-bonehunter.png"> to identify the Pubis.</p>

        <p class="image-row"><img alt="Incorrect screen" src="/assets/img/guide/anatomy/incorrect-bonehunter.png"></p>

        <h3>Correct Response</h3>

        <p>If you answer correctly, bubbles explode across the screen, the bone selected illuminates green, and your Bone Streak goes up by one. In this example, Pubis is identified correctly and lights up green. To rotate the skeleton, use the left or right arrows. <img class="icon-image" alt="rotate button" src="/assets/img/guide/anatomy/rotate-left-bonehunter.png">&nbsp;<img class="icon-image" alt="rotate button" src="/assets/img/guide/anatomy/rotate-right-bonehunter.png"></p>

        <p class="image-row"><img alt="Correct screen" src="/assets/img/guide/anatomy/correct-bonehunter.png"></p>

        <div class="image-row-wrap">
          <div>
            <h3>Study Groups</h3>

            <p>To change the areas for identifying the bones, select the Menu button <img class="icon-image" alt="menu button" src="/assets/img/guide/anatomy/menu-button-bonehunter.png"> in the top right corner of the screen. Then check or uncheck the desired areas to identify bones in the Bone Hunter.</p>

            <h3>Reset View</h3>

            <p>To reset the view of the skeleton, select the target button <img class="icon-image" alt="target button" src="/assets/img/guide/anatomy/target-bonehunter.png"> in the bottom right corner.</p>

            <h3>Zoom In</h3>

            <p>To zoom in on the ear bones, hand, or foot, select the Plus button <img class="icon-image" alt="zoom button" src="/assets/img/guide/anatomy/zoom-bonehunter.png"> next to the skull, right hand, or right foot.</p>
          </div>

          <img alt="Study Groups" src="/assets/img/guide/anatomy/study-groups.png">
        </div>
      </section>
    </article>
  </div>
