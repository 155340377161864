<img src="/assets/img/anatomy/orange_swooshed.png" class="tinysaur orange" alt="U.S. Army STARS Anatomy Character">
<img src="/assets/img/anatomy/blue_swooshed.png" class="tinysaur blue" alt="U.S. Army STARS Anatomy Character">
<img src="/assets/img/anatomy/red_swooshed.png" class="tinysaur red" alt="U.S. Army STARS Anatomy Character">
<img src="/assets/img/anatomy/mobile-characters.png" class="tinysaur-group" alt="U.S. Army STARS Anatomy Characters">

<div class="wrapper">
  <img src="/assets/img/anatomy/stars-anatomy-logo.png" alt="U.S. Army STARS Anatomy Logo">

  <h2>Explore the Human Skeleton</h2>

  <div class="store-buttons">
    <a href="https://apps.apple.com/us/app/stars-anatomy/id1458763783" target="_blank" class="app-store-button">
      <img src="/assets/img/apple-store-badge.svg" alt="Apple Store">
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.ArmyGameStudio.STARS_Anatomy" target="_blank"
      class="app-store-button">
      <img src="/assets/img/google-play.svg" alt="Google Play Store">
    </a>
  </div>
</div>