import { Component, Input } from '@angular/core';

@Component({
  selector: 'stars-army-footer',
  templateUrl: './army-footer.html',
  styleUrls: ['./army-footer.scss']
})
export class ArmyFooterComponent {
  @Input() logoAssetsPath: string;
  @Input() assetsPath: string;

  copyright: number = new Date().getFullYear();
}
