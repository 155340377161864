<div class="content">

  <aside>
    <h3>STARS: <em>Elements</em> Guide:</h3>
    <a class="active" (click)="scrollToSection('overview')">Overview</a>
    <ul>
      <li><a (click)="scrollToSection('system-requirements')">System Requirements</a></li>
      <li><a (click)="scrollToSection('start-screen')">Start Screen</a></li>
      <li><a (click)="scrollToSection('periodic-table')">Periodic Table</a></li>
      <li><a (click)="scrollToSection('element-view')">Element Info View</a></li>
      <li><a (click)="scrollToSection('atom-builder')">Atom Builder</a></li>
      <li><a (click)="scrollToSection('smashbond')">SmashBond</a></li>
      <li><a (click)="scrollToSection('stars-challenge')">Stars Challenge</a></li>
      <li><a (click)="scrollToSection('meet-characters')">Meet the Characters</a></li>
    </ul>

    <section class="promotion-area">
      <div>
        <img src="/assets/img/march2success.png" alt="" />
        <h2>March 2 Success</h2>
        <p>March 2 Success offers educators, parents, mentors and students a free program to help children meet their educational
          goals. Whether a student is preparing for college, military or entering the civilian workforce, test scores can
          play an important role in their future. March 2 Success provides access to online study materials to help improve
          scores on standardized tests such as state exit exams, college entrance exams, military entrance exam (ASVAB) and
          others.</p>
        <p>Visit <a href="https://www.march2success.com/">March 2 Success</a> for more information.</p>
      </div>
    </section>
  </aside>

  <article class="guide-area">
    <header id="overview">
      <h1>Overview</h1>
      <h2>STARS: <em>Elements</em> is a free educational application developed by The Army Game Studio at Systems Simulation, Software, and Integration (S3I) in collaboration with U.S. Army STEM experts and educators. STARS: <em>Elements</em> is yet another
        in a series of leading Army STEM innovation products and programs.</h2>
        <article class="button-row">
          <button (click)="scrollToSection('periodic-table')">Interactive Periodic Table</button>
          <button (click)="scrollToSection('element-view')">Element Info View</button>
          <button (click)="scrollToSection('atom-builder')">Atom Builder</button>
          <button (click)="scrollToSection('smashbond')">Smashbond</button>
          <button (click)="scrollToSection('stars-challenge')">STARS Challenge</button>
          <button (click)="scrollToSection('meet-characters')">Meet the Characters</button>
        </article>
      <img src="/assets/img/guide/stars-website.png" alt="" />
      <p>The free online application is accessible to anyone via the Unity Web Player and is available as a standalone mobile
        app on iTunes and Google Play. STARS: <em>Elements</em> is intended to aid high school chemistry teachers in teaching
        and visualizing the periodic table of the elements, elementary chemical bonds, and atomic structure.</p>
    </header>

    <section>
      <h3 id="system-requirements">System Requirements:</h3>
      <h4>WebGL</h4>
        <ul>
          <li>Window 7/8/8.1 on the following browsers: Chrome and Firefox</li>
          <li>Windows 10 on the following browsers: Chrome, Firefox and Edge</li>
          <li>OSX on the following browsers: Chrome, Firefox and Safari</li>
        </ul>
      <h4>iOS</h4>
          <ul>
            <li>Minimum
              <ul>
                <li>iOS version 6.0</li>
                <li>iPad 2 or iPhone 4</li>
              </ul>
            </li>
            <li>Recommended
              <ul>
                <li>iPad Air or iPhone 5 and higher</li>
              </ul>
            </li>
          </ul>
        <h4>Android</h4>
        <ul>
          <li>Minimum
            <ul>
              <li>2.3.1 (API level 10)</li>
            </ul>
          </li>
          <li>Recommended
            <ul>
              <li>5.0 and higher</li>
            </ul>
          </li>
        </ul>
    </section>



    <section id="start-screen">
      <h2>Start Screen</h2>
      <img src="/assets/img/guide/start-screen.png" alt="" />
      <p>The Elements has the following main areas to explore:</p>
      <ul>
        <li>Periodic Table</li>
        <li>Atom Builder</li>
        <li>STARS Challenge</li>
        <li>SmashBond</li>
      </ul>
      <p>In addition, there is a corresponding website (<a href="http://usarmystars.com">www.usarmystars.com</a>) which includes
        teacher resources and an Army STEM page.</p>

      <h3>Interactive Periodic Table</h3>
      <p>STARS: <em>Elements</em> provides a fully interactive periodic table listing of all known elements (1 through 118).
        Initially, only the element’s atomic number and symbol are displayed. However, hovering over an element pops up a
        field displaying the element's full name, atomic number and properties.</p>
      <p>The various Groups (other nonmetals, metalloids, etc.) are color-coded and match a corresponding legend. Other information
        related to the element is contained within the Element Info view which is opened by selecting the element.</p>

      <h3>Element Info</h3>
      <p>The Element Info view includes a variety of facts and attributes of the element along with the element's Bohr model.
        The electron shells and the nucleus of the model may be selected to highlight additional information. Selecting from
        the list of electron energy levels (located along the right side of the screen) allows the student to view the specific
        number of electrons in that energy shell.</p>

      <h3>Atom Builder</h3>
      <p>The Atom Builder is a model where the student can create and manipulate atoms within a “3D environment” for purposes
        of exploration, discovery, and learning. It is intended to reinforce and help visualize the particulate nature of
        matter. It is accessible directly via the Main Menu or via any Element Info view.</p>
      <p>Students are free to add/subtract protons, electrons, and neutrons as desired. As protons are added or removed the
        element changes as appropriate (i.e. adding a proton to hydrogen changes the display to helium). If an ion or isotope
        is generated, this is indicated; also, the generated atom is identified as stable or unstable, based on nucleus stability.
        Any element between hydrogen and ununoctium (element 118) may be generated.</p>
      <p>The 3D object may be rotated (holding down the mouse or finger and moving it around the screen) and zoomed (using the
        mouse wheel or finger pinch). The same electron shell control as in the Element Info view is visible on the right
        side to hide or show the various electron shells.</p>

      <h3>SmashBond</h3>
      <p>The SmashBond game is an interactive game based on the Octet Rule. Students are challenged to combine elements on a
        grid in such a way that each atom has eight electrons in its valence shell (or in the case of hydrogen, only 2!).
        It is intended as a spin on the familiar "Match Three" gameplay seen in numerous games, but with a little chemistry
        instead of color-matching.</p>

      <h3>The STARS Challenge</h3>
      <p>The STARS Challenge allows students to test themselves on what they've learned from using the STARS: <em>Elements</em>        app. In this collection of challenges, students are presented with 10 exercises/questions on the three categories
        of knowledge referenced in the app (Periodic Table, Atomic Structure, and Bonding).</p>
    </section>



    <section id="periodic-table">
      <h2>Periodic Table</h2>
      <img src="/assets/img/periodic-table.png" alt="" />
      <ul>
        <li>Other Nonmetals</li>
        <li>Metalloids</li>
        <li>Halogens</li>
        <li>Nobel Gases</li>
        <li>Alkali Metals</li>
        <li>Alkaline Earth Metals</li>
        <li>Lanthanides</li>
        <li>Actinides</li>
        <li>Transition Metals</li>
        <li>Post-transition Metals</li>
      </ul>
      <p>The Periodic Table in this application presents a different approach on how the table is displayed and its interactivity.
        Specifically, the table first appears in a 'low information' state. The student is encouraged with visual cues to
        explore and discover. This reveals and rewards with more information presented in a visually friendly way.</p>
      <ul>
        <li>Blocks:
          <ul>
            <li>The Periodic Table blocks are delineated visually and referenced with an overall highlight that encompasses the
              series within them.</li>
          </ul>
        </li>
        <li>Periods &amp; Groups:
          <ul>
            <li>The Periods and Groups are the horizontal and vertical designations for the elements and one of the key representations
              for trending in the Periodic Table.</li>
          </ul>
        </li>
        <li>Tiles:
          <ul>
            <li>These Tiles represent each element and have a base level of simple information. When a student hovers/long holds
              over an element, the mini-card appears. It contains:
              <ul>
                <li>Element Name</li>
                <li>Atomic Number</li>
                <li>Atomic Radius</li>
                <li>1<sup>st</sup> Ionization Energy</li>
                <li>Electron Affinity</li>
                <li>Electronegativity</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>Selecting the tile, transitions it to the Element Info view for the particular element.</p>
    </section>



    <section id="element-view">
      <h2>Element Info View</h2>
      <img src="/assets/img/guide/element-info.png" alt="" />
      <p>The Element Info view includes a selection of facts and attributes of that element and its Bohr model. The electron
        shells and the nucleus of the model may be selected to highlight additional information. A control is located along
        the right side of the screen as an alternative means of stepping through the electron shells.</p>
      <p>A "mini-map" of the Periodic Table is included to inform the student where in the table the current element is located.
        Within this view, the student may choose to explore the current element in the Atom Builder.</p>

      <h3>Features</h3>
      <ul>
        <li>Periodic Table button, on the top left of the screen, returns the student to the Periodic Table screen.</li>
        <li>Atom Builder button, in the top right of the screen, takes the student to the Atom Builder screen which builds the
          current element.</li>
        <li>Information panel, located to the left of the Bohr model, provides the student with facts, characteristics, properties,
          trivia, and common Army uses of the element.</li>
        <li>Centered on the screen, is the Bohr Model and the electron shell menu to the right.</li>
      </ul>
    </section>



    <section id="atom-builder">
      <h2>Atom Builder</h2>
      <img src="/assets/img/atom-builder.png" alt="" />
      <p>The Atom Builder allows students and teachers to visualize and build atoms in 3D with interactive tools. This includes:</p>
      <ul>
        <li>Controls for building and modifying a 3D atom.</li>
        <li>Controls for manipulating a 3D atom in space (this has a natural, intuitive manual feel, i.e. clicking and dragging
          on an atom will rotate it in that direction).</li>
      </ul>
      <p>This is a model where the student can create and manipulate atoms within a 3D display for purposes of exploration,
        discovery, and learning. The model is intended to support and reinforce visualization of this particulate nature
        of matter. It is accessible directly via the Main Menu or via any Element Info view.</p>
      <p>Students are free to add/subtract protons, electrons, and neutrons as desired. As protons are added or subtracted,
        the element changes as appropriate (i.e. adding a proton to hydrogen changes the display to helium). If an ion or
        isotope is generated, this is indicated and identified as stable or unstable. Any element between hydrogen and ununoctium
        (element 118) may be generated. The 3D object may be rotated and zoomed. The same electron shell control as in the
        Element Info view is visible on the right side to hide or show the various electron shells. Features include:</p>
      <ul>
        <li><strong>Basic Visual Components</strong> of the atom i.e. electrons, neutrons, protons.</li>
        <li><strong>Basic Element Information</strong> i.e. element name, atomic number, molar mass, nucleus stability etc.</li>
        <li><strong>Building Tools</strong> for adding and subtracting electrons, neutrons, and protons.</li>
        <li><strong>Manipulation Controls</strong> - Spin/Rotate: Zoom In/Out.</li>
      </ul>
    </section>



    <section id="smashbond">
      <h2>SmashBond</h2>
      <img src="/assets/img/smashbond-board.png" alt="" />
      <p>SmashBond is a match-3 game that teaches the basic concepts behind molecular bonding. Its purpose is to enhance teaching
        the fundamental concept of the Octet Rule within the framework of a fun, challenging game. While it can be played
        solo by a student once they understand the basic concepts of how to play and win the experience, the game is best
        suited initially for interaction between a teacher and student. The image above is a typical example of the opening
        screen when the student starts Smashbond with screen components identified.</p>

      <h3>How to Play SmashBond</h3>
      <p>SmashBond is a grid of tiles that each represent an element symbol and the number of electrons in their valence shell
        in the Lewis Dot Structure style. The player is given a set number of ‘moves’ (actions) to earn at least a minimum
        score that allows them to go on to the next level.</p>
      <p>To play the game, just tap/click and drag a tile over top of another adjacent tile and then release. The two tiles
        will swap and if a proper compound match results, they will disappear, score, and new tiles will ‘fall down’ from
        the top to replace them. Incidental matches are possible!</p>
      <img src="/assets/img/guide/smashbond-2.png" alt="" />
      <p>A few notes on gameplay:</p>
      <ul>
        <li>The tile set consists of 22 atoms (from the first 4 periods minus the Transition Metals and Noble Gases).</li>
        <li>Currently 2, 3, and even 4 atom chemical compounds are possible.</li>
        <li>Each level has a scoring objective to pass the level. If it is reached before all the moves are used, the level will
          end, with additional scoring given for each remaining move left.</li>
        <li>Matches that are made are recorded in a list onscreen and when selected, bring up an overlay with additional data
          (compound name, Lewis Dot structure and type of bond (ex. covalent or ionic bond)).</li>
      </ul>

      <h3>SmashBond Objectives</h3>
      <p>SmashBond was designed to present information in an entertaining way for students to learn, progress through the game
        and materials, and accomplish goals. The game adds an interesting new approach for educators to teach the basic concepts
        behind the Octet rule and molecular bonding. As with any game, it is important that players have goals to accomplish
        and have a sense of progression.</p>
      <p>In the game,‘bonus objectives’ are essentially blueprints for other compounds. Each time a bonus objective is generated,
        the player receives extra points. Bonus objectives have several purposes:</p>
      <ul>
        <li>Provide the students with some molecular combinations they may not have known about.</li>
        <li>Provide the student with a challenge as a meta-goal in addition to passing the level.</li>
        <li>Incentivize the player through goals and progression and an element of ‘deeper strategy’.</li>
        <li>Provide an opportunity for a teacher to expound on what uses those newly made compounds have in relatable, daily
          life.</li>
      </ul>
    </section>



    <section id="stars-challenge">
      <h2>STARS Challenge</h2>
      <img src="/assets/img/stars-challenge.png" alt="" />
      <p>The goal of the STARS Challenge is to receive 5 stars by successfully answering 10 challenge questions. Each question
        correctly answered is worth a half star. Scores are persistent on a per-session basis. There are 4 types of questions
        presented with each tailored to the individual challenge. The master challenge consists of 20 questions from each
        of the three topics: Elements, Bonding and Atomic.</p>

      <div class="split">
        <img src="/assets/img/guide/multiple-choice.png" alt="">
        <section>
          <h3>Multiple Choice</h3>
          <p>Multiple-choice questions are best used for checking whether students have learned facts and routine procedures
            that have one, clearly correct answer.</p>
          <p>Students are presented with a question and must select the correct answer from 4 options.</p>
        </section>

        <img src="/assets/img/guide/matching.png" alt="">
        <section>
          <h3>Matching</h3>
          <p>Matching a statement to an answer is great for sorting information, especially information that is visual in nature
            or process-oriented.</p>
          <p>This type of question allows students to interact with objects on the screen and match the drag items with the
            drop targets.</p>
        </section>

        <img src="/assets/img/guide/identify-symbol.png" alt="">
        <section>
          <h3>Identify Element Symbol</h3>
          <p>This question type presents two lists of related words, phrases, pictures, or symbols. Each item in one list is
            paired with at least one item in the other list.</p>
          <p>Students are presented with an element’s name and a grid of element symbols. They are to select the correct symbol
            that matches the name.</p>
        </section>

        <img src="/assets/img/guide/select-bond.jpg" alt="">
        <section>
          <h3>Select the Correct Bond</h3>
          <p>Similar to matching, students are presented with the Lewis Dot structure of an atom and they must select from a
            group of atoms to determine which one would correctly bond.</p>
        </section>
      </div>
    </section>



    <section id="meet-characters">
      <h2>Meet the Characters</h2>

      <div class="split">
        <img src="/assets/img/guide/characters/actinide.jpg" alt="">
        <section>
          <h3>Actinide</h3>
          <p>The actinide or actinoid series encompasses 15 consecutive radioactive chemical elements in the periodic table
            from actinium to lawrencium and gets its name from the first element in the series.</p>
        </section>

        <img src="/assets/img/guide/characters/alkali.jpg" alt="">
        <section>
          <h3>Alkali Metals</h3>
          <p>Alkali metals are any of the metals found in Group 1 of the periodic table. They are very reactive, electropositive,
            monovalent, and do not occur freely in nature.</p>
          <p>Alkali metals are malleable, ductile, and are good conductors of heat and electricity.</p>
        </section>

        <img src="/assets/img/guide/characters/alkaline.jpg" alt="">
        <section>
          <h3>Alkaline Earth Metals</h3>
          <p>The alkaline earth elements are metallic elements found in the second group of the periodic table.</p>
          <p>All alkaline earth elements have an oxidation number of +2, making them very reactive.</p>
        </section>

        <img src="/assets/img/guide/characters/halogens.jpg" alt="">
        <section>
          <h3>Halogens</h3>
          <p>The halogens are five non-metallic elements found in Group 17 of the periodic table. Halogen means “salt-former”
            and compounds containing halogens are called “salts”.</p>
          <p>All halogens have 7 electrons in their outer shells.</p>
        </section>

        <img src="/assets/img/guide/characters/lanthanide.jpg" alt="">
        <section>
          <h3>Lanthanide</h3>
          <p>The lanthanide or lanthanoid series of chemical elements comprises the 15 consecutive metallic elements from lanthanum
            to lutetium in the periodic table.</p>
          <p>Their atoms have similar configurations and similar physical and chemical behavior.</p>
        </section>

        <img src="/assets/img/guide/characters/noble.jpg" alt="">
        <section>
          <h3>Noble Gases</h3>
          <p>The gaseous elements occupying Group18 of the periodic table. Their outer shell of valence electrons is considered
            to be full.</p>
        </section>

        <img src="/assets/img/guide/characters/nonmetals.jpg" alt="">
        <section>
          <h3>Other Nonmetals</h3>
          <p>Other Nonmetals are the elements in Groups 14-16 of the periodic table. Other Nonmetals are not able to conduct
            electricity or heat very well.</p>
        </section>

        <img src="/assets/img/guide/characters/post-transition.jpg" alt="">
        <section>
          <h3>Post-Transition Metals</h3>
          <p>Post-transition metals are the metallic elements in the periodic table located between the transition metals and
            the metalloids.</p>
          <p>Post-transition metals have lower melting and boiling points with higher electronegativity and are generally softer
            than transition metals.</p>
        </section>

        <img src="/assets/img/guide/characters/transition.jpg" alt="">
        <section>
          <h3>Transition Metals</h3>
          <p>Transition metals are any of the set of metallic elements occupying a central block in the periodic table. They
            show variable valence and a strong tendency to form coordination compounds. Many of their compounds are colored.</p>
        </section>

        <img src="/assets/img/guide/characters/metalloids.jpg" alt="">
        <section>
          <h3>Metalloids</h3>
          <p>Metalloids are the elements found along the stair-step line that distinguishes metals from non-metals. They are
            electrical semiconductors.</p>
        </section>

      </div>
    </section>
  </article>
</div>
