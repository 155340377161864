import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Angulartics2Module, Angulartics2GoogleTagManager } from 'angulartics2';

import { MenuComponent } from './components/menu';
import { HeaderComponent } from './components/header';
import { AnatomyOverviewComponent } from './components/anatomy/anatomy-overview';
import { AnatomyCharactersComponent } from './components/anatomy/anatomy-characters';
import { AnatomyFeaturesComponent } from './components/anatomy/anatomy-features';
import { AnatomyPlatformsComponent } from './components/anatomy/anatomy-platforms';
import { ElementsOverviewComponent } from './components/elements/elements-overview';
import { ElementsCharactersComponent } from './components/elements/elements-characters';
import { ElementsFeaturesComponent } from './components/elements/elements-features';
import { ElementsPlatformsComponent } from './components/elements/elements-platforms';
import { ElementsResourcesComponent } from './components/elements/elements-resources';
import { SliderComponent } from './components/slider/slider';
import { ArmyFooterComponent } from './components/army-footer';

import { ScrollToDirective } from './directives/scroll-to';

import { AppComponent } from './app';

import { routes, routeComponents } from './routes';
import { reducers } from './reducer';
import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { TealiumUtagService } from './tealium/utag.service';

import { GuestPageComponent } from './pages/guest.component';
import { GuestAnatomyComponent } from './components/guest-anatomy/guest-anatomy.component';
import { GuestElementsComponent } from './components/guest-elements/guest-elements.component';
import { GuestTeacherResourcesComponent } from './components/guest-teacher-resources/guest-teacher-resources.component';
import { LessonElementsComponent } from './components/lesson-elements/lesson-elements.component';
import { LessonAnatomyComponent } from './components/lesson-anatomy/lesson-anatomy.component';
import { TheodoreRexDialogComponent } from './components/anatomy/anatomy-characters-animations/theodore-rex-dialog/theodore-rex-dialog.component';
import { ToppsTrikalaDialogComponent } from './components/anatomy/anatomy-characters-animations/topps-trikala-dialog/topps-trikala-dialog.component';
import { PlatoMcstegoDialogComponent } from './components/anatomy/anatomy-characters-animations/plato-mcstego-dialog/plato-mcstego-dialog.component';
import { RowanBeanDialogComponent } from './components/anatomy/anatomy-characters-animations/rowan-bean-dialog/rowan-bean-dialog.component';
import { GuideAnatomyComponent } from './components/guide-anatomy/guide-anatomy.component';
import { GuideElementsComponent } from './components/guide-elements/guide-elements.component';
import { GuestPartnersComponent } from './components/guest-partners/guest-partners.component';
import { ElementsCharacterModalComponent } from './components/elements/elements-character-modal/elements-character-modal.component';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    BrowserAnimationsModule,
    OverlayModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    StoreModule.forRoot(reducers),
    Angulartics2Module.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 25, connectInZone: true })
      : []
  ],
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    GuestPageComponent,
    AnatomyOverviewComponent,
    AnatomyCharactersComponent,
    AnatomyFeaturesComponent,
    AnatomyPlatformsComponent,
    ElementsOverviewComponent,
    ElementsFeaturesComponent,
    ElementsPlatformsComponent,
    ElementsResourcesComponent,
    ElementsCharacterModalComponent,
    SliderComponent,
    ArmyFooterComponent,
    ElementsCharactersComponent,
    ScrollToDirective,
    routeComponents,
    GuestAnatomyComponent,
    GuestElementsComponent,
    GuestTeacherResourcesComponent,
    LessonElementsComponent,
    LessonAnatomyComponent,
    TheodoreRexDialogComponent,
    ToppsTrikalaDialogComponent,
    PlatoMcstegoDialogComponent,
    RowanBeanDialogComponent,
    GuideAnatomyComponent,
    GuideElementsComponent,
    GuestPartnersComponent,
    ElementsCharacterModalComponent
  ],
  providers: [
    { provide: TealiumUtagService, useClass: TealiumUtagService },
    { provide: MatDialogRef, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
