import { Component } from '@angular/core';

@Component({
  selector: 'stars-lesson-elements',
  templateUrl: './lesson-elements.component.html',
  styleUrls: ['../../pages/lessons.scss']
})
export class LessonElementsComponent {
  scrollToSection(sectionId: string): void {
    const section = document.getElementById(sectionId);
    const sectionPosition = section.offsetTop;
    const offsetPosition = sectionPosition - 150;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}
