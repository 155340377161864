<h2>Built on flexible platforms for any classroom</h2>

<!-- TODO: Update with Anatomy text once available -->
<p>Whether working on desktop or notebook computers, tablets or phones, iOS or Android, STARS: <em>Anatomy</em> will run
  on almost any platform so that students can learn inside and outside of the classroom.</p>

<img class="devices" src="/assets/img/devices.svg" alt="Devices Icon">

<h3>Choose your platform:</h3>

<div class="store-buttons">
  <!-- TODO: Update with Anatomy links once available -->
  <a href="https://apps.apple.com/us/app/stars-anatomy/id1458763783" target="_blank"><img
      src="/assets/img/apple-store-badge.svg" alt="Apple Store"></a>
  <a href="https://play.google.com/store/apps/details?id=com.ArmyGameStudio.STARS_Anatomy" target="_blank"><img
      src="/assets/img/google-play-badge.svg" alt="Google Play Store"></a>
  <a href="https://anatomy.usarmystars.com/" target="_blank"><img src="/assets/img/web-player.svg" alt="Web Player"></a>
</div>
