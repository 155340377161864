import { ActionReducerMap } from '@ngrx/store';
import * as fromMenu from './menu';

export interface State {
  menu: fromMenu.state;
}

export const reducers: ActionReducerMap<State> = {
  menu: fromMenu.reducer
};
