import { Component, HostBinding } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';

import { State } from './reducer';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { TealiumUtagService } from './tealium/utag.service';

@Component({
  selector: 'stars-app',
  styleUrls: ['./app.scss'],
  template: `
    <stars-header></stars-header>

    <router-outlet></router-outlet>

    <stars-army-footer logoAssetsPath="/assets/img"></stars-army-footer>
  `,
  providers: [TealiumUtagService]
})
export class AppComponent {
  @HostBinding('class.main-menu-open') menuOpen = false;

  constructor(
    private store: Store<State>,
    private router: Router,
    private angularticsGTM: Angulartics2GoogleTagManager,
    private tealium: TealiumUtagService
  ) {
    store
      .select((s) => s.menu)
      .subscribe((menuOpen) => (this.menuOpen = menuOpen));

    this.tealium.setConfig({
      account: 'goarmy',
      profile: 'redstone',
      environment: 'prod'
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({
          top: 0
        });
        switch (event.url) {
          case '/':
            this.tealium.view({
              page_type: 'overview'
            });
            break;

          case '/apps/anatomy':
            this.tealium.view({
              page_type: 'anatomy'
            });
            break;

          case '/apps/elements':
            this.tealium.view({
              page_type: 'elements'
            });
            break;

          case '/stem':
            this.tealium.view({
              page_type: 'stem'
            });
            break;

          case '/lessons':
            this.tealium.view({
              page_type: 'lessons'
            });
            break;

          case '/guides':
            this.tealium.view({
              page_type: 'guides'
            });
            break;

          case '/contact':
            this.tealium.view({
              page_type: 'contact'
            });
            break;

          case '/legal/privacy':
            this.tealium.view({
              page_type: 'privacy'
            });
            break;

          case '/legal/terms':
            this.tealium.view({
              page_type: 'terms'
            });
            break;

          default:
            break;
        }
      }
    });
  }
}
