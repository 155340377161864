import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[starsScrollTo]'
})
export class ScrollToDirective {
  @Input() href: string;

  constructor(private router: Router) {}

  @HostListener('click')
  onClick() {
    window.location.href = `${this.router.url.split('#')[0]}${this.href}`;

    return false;
  }
}
