import { Component } from '@angular/core';
import { Careers } from './stem.careers';

import { TealiumUtagService } from '../tealium/utag.service';

const CAREERS: Careers[] = [
  {
    jobTitle: 'Technical Engineer - 12T',
    fullJobTitle: 'Technical Engineer - 12T',
    category: 'Engineering',
    image: '/assets/img/stem/technical_engineer.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/construction-engineering/technical-engineer.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/engineering-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'General Surgeon - 61J',
    fullJobTitle: 'General Surgeon - 61J',
    category: 'Medical Corps',
    image: '/assets/img/stem/gen-surgeon.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs/general-surgeon.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Unit Supply Specialist – 92Y',
    fullJobTitle: 'Unit Supply Specialist – 92Y',
    category: 'Mathematics',
    image: '/assets/img/stem/unit_supply_spec.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/administrative-support/unit-supply-specialist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/math-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Medical Surgical Nurse - 66H',
    fullJobTitle: 'Medical Surgical Nurse - 66H',
    category: 'Science',
    image: '/assets/img/stem/medical_surgical_nurse.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/amedd-categories/nurse-corps-jobs/medical-surgical-nurse.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Engineer Officer - 12A',
    fullJobTitle: 'Engineer Officer - 12A',
    category: 'Engineering',
    image: '/assets/img/stem/engineer_officer.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/construction-engineering/engineer-officer.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/engineering-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Pharmacy Specialist - 68Q',
    fullJobTitle: 'Pharmacy Specialist - 68Q',
    category: 'Science',
    image: '/assets/img/stem/pharmacy_specialist.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/medical-and-emergency/pharmacy-specialist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Cardiologist - 60H',
    fullJobTitle: 'Cardiologist - 60H',
    category: 'Medical Corps',
    image: '/assets/img/stem/cardiologist.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs/cardiologist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Dietitian - 65C',
    fullJobTitle: 'Dietitian - 65C',
    category: 'Science',
    image: '/assets/img/stem/dietitian.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/amedd-categories/medical-specialist-corps-jobs/dietitian.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Geospatial Engineer - 12Y',
    fullJobTitle: 'Geospatial Engineer - 12Y',
    category: 'Engineering',
    image: '/assets/img/stem/geospatial_engineer.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/construction-engineering/geospatial-engineer.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/engineering-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Biochemist / Physiologoist - 71B',
    fullJobTitle: 'Biochemist / Physiologoist - 71B',
    category: 'Science',
    image: '/assets/img/stem/biochemist_physiologist.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/amedd-categories/medical-service-corps-jobs/biochemist-physiologist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Neurosurgeon - 61Z',
    fullJobTitle: 'Neurosurgeon - 61Z',
    category: 'Medical Corps',
    image: '/assets/img/stem/neurosurgeon.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs/neurosurgeon.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Automated Logistical Specialist – 92A',
    fullJobTitle: 'Automated Logistical Specialist – 92A',
    category: 'Mathematics',
    image: '/assets/img/stem/auto_logistic_spec.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/intelligence-and-combat-support/automated-logistical-specialist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/math-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Test Measurement and Diagnost… – 94H',
    fullJobTitle:
      'Test Measurement and Diagnostic Equipment Maintenance Support Specialist – 94H',
    category: 'Technology',
    image: '/assets/img/stem/test_measure_diag.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/computers-and-technology/test-measurement-and-diagnostic-equipment-maintenance-support-specialist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/technology-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Warrant Officer Corps - 09W',
    fullJobTitle: 'Warrant Officer Corps - 09W',
    category: 'Technology',
    image: '/assets/img/stem/warrent_officer_corps.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/transportation-and-aviation/warrant-officer-corps.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/technology-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Financial Management Technician - 36B',
    fullJobTitle: 'Financial Management Technician - 36B',
    category: 'Mathematics',
    image: '/assets/img/stem/financial_management_technician.jpg',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/administrative-support/financial-management-technician.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/math-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Microbiologist - 71A',
    fullJobTitle: 'Microbiologist - 71A',
    category: 'Medical Services Corps',
    image: '/assets/img/stem/microbiologist.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-service-corps-jobs/microbiologist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-service-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    jobTitle: 'Pathologist - 61U',
    fullJobTitle: 'Pathologist - 61U',
    category: 'Medical Corps',
    image: '/assets/img/stem/pathologist.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs/pathologist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  }
];

@Component({
  selector: 'stars-stem-page',
  templateUrl: './stem.html',
  styleUrls: ['./stem.scss'],
  providers: [TealiumUtagService]
})
export class StemPageComponent {
  public careers = CAREERS;
}
