import { Component } from '@angular/core';

import { TealiumUtagService } from '../tealium/utag.service';

@Component({
  selector: 'stars-elements-page',
  templateUrl: './elements.html',
  styleUrls: ['./elements.scss'],
  providers: [TealiumUtagService]
})
export class ElementsPageComponent {}
