<section>
  <span class="logo"><img src="/assets/img/elements-logo.png" alt="" /></span>

  <div class="store-buttons">
    <a href="https://itunes.apple.com/us/app/u.s.-army-stars-elements-fun/id1103901837?ls=1&mt=8" target="_blank" class="app-store-button"><img src="/assets/img/apple-store-badge.svg" alt="Apple Store"></a>
    <a href="https://play.google.com/store/apps/details?id=com.agsgamestudio.stars" target="_blank" class="app-store-button"><img src="/assets/img/google-play.svg" alt="Google Play Store"></a>
    <a href="https://elements.usarmystars.com/" target="_blank" class="app-store-button"><img src="/assets/img/web-player.svg" alt="Web Player"></a>
  </div>

  <div class="award-container">
    <img src="/assets/img/sgsc-award.svg" alt="">
    <span>Winner Serious Games Showcase & Challenge 2016</span>
  </div>
</section>
