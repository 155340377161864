import {
  Component,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { SliderImage } from './image.interface';
import { timer, Subscription } from 'rxjs';

const IMAGES: SliderImage[] = [
  {
    title: 'Combat Engineer - 12B',
    url: '/assets/img/stem/carousel/combat_engineer_carousel.jpg',
    category: 'Engineering',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/construction-engineering/combat-engineer.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/engineering-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    title: 'General Surgeon - 61J',
    category: 'Medical Corps',
    url: '/assets/img/stem/gen-surgeon.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs/general-surgeon.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    title: 'Medical Logistics Specialist - 68J',
    url: '/assets/img/stem/carousel/medical_logistics_specialist_carousel.jpg',
    category: 'Science',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/medical-and-emergency/medical-logistics-specialist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    title: 'Medical Surgical Nurse - 66H',
    url: '/assets/img/stem/carousel/medical_surgical_nurse_carousel.jpg',
    category: 'Science',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/amedd-categories/nurse-corps-jobs/medical-surgical-nurse.html?iom=AFOD-2675107_STARS_Website?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website?iom=AFOD-2675107_STARS_Website'
  },
  {
    title: 'Microbiologist - 71A',
    category: 'Medical Services Corps',
    url: '/assets/img/stem/microbiologist.jpg',
    careerLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-service-corps-jobs/microbiologist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'https://www.goarmy.com/careers-and-jobs/amedd-categories/medical-service-corps-jobs.html?iom=AFOD-2675107_STARS_Website'
  },
  {
    title: 'Clinical Laboratory Scientist - 71E',
    url: '/assets/img/stem/carousel/clinical_laboratory_scientist_carousel.jpg',
    category: 'Science',
    careerLink:
      'http://www.goarmy.com/careers-and-jobs/amedd-categories/medical-service-corps-jobs/clinical-laboratory-scientist.html?iom=AFOD-2675107_STARS_Website',
    categoryLink:
      'http://www.goarmy.com/careers-and-jobs/about-army-stem/science-jobs.html?iom=AFOD-2675107_STARS_Website'
  }
];

@Component({
  selector: 'stars-stem-slider',
  templateUrl: './slider.html',
  styleUrls: ['./slider.scss']
})
export class SliderComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('slide') slides: QueryList<ElementRef>;
  currentSlideIndex = 0;
  timeBetweenSlides = 10000; // 10 Seconds
  slideTimerSubscription: Subscription;

  public images = IMAGES;

  ngAfterViewInit(): void {
    this.startSlideAnimation();
  }

  ngOnDestroy(): void {
    if (this.slideTimerSubscription) {
      this.slideTimerSubscription.unsubscribe();
    }
  }

  startSlideAnimation(): void {
    this.slideTimerSubscription = timer(
      this.timeBetweenSlides,
      this.timeBetweenSlides
    ).subscribe(() => {
      this.currentSlideIndex++;
      if (this.currentSlideIndex >= this.slides.length) {
        this.currentSlideIndex = 0;
      }
    });
  }

  goToSlide(selectedIndex: number): void {
    if (selectedIndex === this.currentSlideIndex) {
      return;
    }

    if (this.slideTimerSubscription) {
      this.slideTimerSubscription.unsubscribe();
      this.currentSlideIndex = selectedIndex;
      this.startSlideAnimation();
    }
  }
}
