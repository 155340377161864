import { Component } from '@angular/core';
import { TheodoreRexDialogComponent } from '../anatomy/anatomy-characters-animations/theodore-rex-dialog/theodore-rex-dialog.component';
import { ToppsTrikalaDialogComponent } from '../anatomy/anatomy-characters-animations/topps-trikala-dialog/topps-trikala-dialog.component';
import { PlatoMcstegoDialogComponent } from '../anatomy/anatomy-characters-animations/plato-mcstego-dialog/plato-mcstego-dialog.component';
import { RowanBeanDialogComponent } from '../anatomy/anatomy-characters-animations/rowan-bean-dialog/rowan-bean-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'stars-guide-anatomy',
  templateUrl: './guide-anatomy.component.html',
  styleUrls: ['../../pages/guide.scss']
})
export class GuideAnatomyComponent {
  constructor(public dialog: MatDialog) {}

  launchSkillsVideo(tinysaurName: string) {
    let selectedDialog;

    switch (tinysaurName) {
      case 't-rex':
        selectedDialog = TheodoreRexDialogComponent;
        break;
      case 'triceratops':
        selectedDialog = ToppsTrikalaDialogComponent;
        break;
      case 'stegosaurus':
        selectedDialog = PlatoMcstegoDialogComponent;
        break;
      case 'ankylosaurus':
        selectedDialog = RowanBeanDialogComponent;
        break;
      default:
        return;
    }

    this.dialog.open(selectedDialog, {
      height: '100vh',
      width: '100vw',
      panelClass: 'element-char-modal'
    });
  }

  scrollToSection(sectionId: string): void {
    const section = document.getElementById(sectionId);
    const sectionPosition = section.offsetTop;
    const offsetPosition = sectionPosition - 150;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}
