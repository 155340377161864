import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, Event } from '@angular/router';

@Component({
  selector: 'stars-theodore-rex-dialog',
  templateUrl: './theodore-rex-dialog.component.html',
  styleUrls: ['./theodore-rex-dialog.component.scss']
})
export class TheodoreRexDialogComponent implements OnInit, OnDestroy {
  constructor(
    public theodoreRexDialogRef: MatDialogRef<TheodoreRexDialogComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      this.theodoreRexDialogRef.close();
    });
  }

  ngOnDestroy(): void {
    if (this.theodoreRexDialogRef && this.theodoreRexDialogRef.close) {
      this.theodoreRexDialogRef.close();
    }
  }
}
