<nav>
  <a [ngClass]="{ 'dropdown-active': menuItemDropdown }" class="dropdown-button" (click)="toggleMenuItemDropdown()"><span>Apps</span></a>
  <div class="dropdown">
    <a href="apps/anatomy" routerLinkActive="active" (click)="toggle.emit({})" (click)="toggleMenuItemDropdown()"><span>Anatomy</span></a>
    <a href="apps/elements" routerLinkActive="active" (click)="toggle.emit({})" (click)="toggleMenuItemDropdown()"><span>Elements</span></a>
  </div>
  <a href="/stem" routerLinkActive="active" (click)="toggle.emit({})"><span>STEM</span></a>
  <a href="/lessons" routerLinkActive="active" (click)="toggle.emit({})"><span>Lessons</span></a>
  <a href="/guides" routerLinkActive="active" (click)="toggle.emit({})"><span>Guides</span></a>
  <a href="/contact" routerLinkActive="active" (click)="toggle.emit({})"><span>Contact</span></a>
</nav>
