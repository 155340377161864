import { Component } from '@angular/core';

import { TealiumUtagService } from '../tealium/utag.service';

@Component({
  selector: 'stars-lessons-page',
  templateUrl: './lessons.html',
  styleUrls: ['./lessons.scss'],
  providers: [TealiumUtagService]
})
export class LessonsPageComponent {}
