<div class="content">
  <aside>
    <h3>Lesson Plan By Category:</h3>

    <section class="category">
    <h4>Anatomy</h4>
    <a class="active" (click)="scrollToSection('lessons-area1')">Analyze and apply the functions of the human skeleton to better distinguish the relationships of the human body</a>

		<ul>
			<li><a class="" (click)="scrollToSection('lessons-area1')">Description</a></li>
			<li><a class="" (click)="scrollToSection('expectations')">Expectations</a></li>
			<li><a class="" (click)="scrollToSection('narrative')">Narrative</a></li>
			<li><a class="" (click)="scrollToSection('engage')">Engage</a></li>
			<li><a class="" (click)="scrollToSection('explore')">Explore</a></li>
			<li><a class="" (click)="scrollToSection('explain')">Explain</a></li>
			<li><a class="" (click)="scrollToSection('elaborate')">Elaborate</a></li>
			<li><a class="" (click)="scrollToSection('evaluate')">Evaluate</a></li>
			<li><a class="" (click)="scrollToSection('accommodations')">Accommodations</a></li>
		</ul>

    </section>
  </aside>

  <article class="lessons-area" id="lessons-area1">
    <header>
      <h3>Lesson Description:</h3>

      <h1>Enable students to analyze and apply the functions of the human skeleton to better distinguish the relationships of the human body.</h1>

      <a class="button" href="/assets/docs/stars-anatomy-lesson.pdf" target="_blank">Download Lesson</a>
    </header>

    <section class="expectations" id="expectations">
      <h3>Performance Expectation(s):</h3>

      <p>HS-LS1-2. Develop and use a model to illustrate the hierarchical organization of interacting systems that provide specific functions within multicellular organisms.</p>

      <h3>Lesson Duration:</h3>

      <p>The intended lesson plan is to cover a unit lesson for the skeletal system which takes approximately three to five days. If this lesson plan is only needed to conduct a daily lesson, then use only one of the three guiding questions to cover the skeletal system.</p>

      <h3>Specific Learning Outcomes:</h3>

      <p>What should students know and understand as a result of this lesson?</p>

      <ol>
        <li>Students identify and investigate the general functions of the skeletal system.</li>

        <li>Students explain the importance of the skeletal system for a healthy functioning body.</li>

        <li>Students determine how the skeletal system functions to provide the body structure, movement, protection, blood cell production, and storage.</li>

        <li>Students synthesize information from readings, interactive skeletal 3D model, and discussions and then apply this new information into their presentations on the skeletal system.</li>
      </ol>
    </section>

    <section class="narrative" id="narrative">
      <h2>Narrative / Background Information</h2>

	  <br>

      <h3>Prior Student Knowledge:</h3>

      <p>What prior knowledge should students have for this lesson?</p>

      <ul>
        <li>Students need to have prior experience with science note-booking.</li>

        <li>Students need to have prior knowledge of summarizing a text.</li>
      </ul>

	  <br>

      <h3>Brief Overview of Lesson:</h3>

      <p>Students read <strong>engaging</strong> bone facts about what the functions of the skeletal system and perform a little activity that requires the students to draw a small skeleton in their notebooks. Students then <strong>explore</strong>, in small groups, the 3D model and Augmented Reality (AR) through the STARS: Anatomy app of the skeleton and how bones are grouped. The students look at the bones for evidence of structure and components and they speculate about the purpose the bone serves based on its physical appearance. Next, students research the main functions of the skeletal system. The teacher follows up with a class discussion about "What are the five main functions of the skeletal system" and students write a summary <strong>explanation</strong> in their notebooks. For further <strong>elaboration</strong>, students watch a video clip which is a guided tour of the skeleton and its functions, and then begin the Quest area in the STARS: Anatomy app, which allows students to collect bones through game play and skeletal quizzes. Lastly, students are <strong>evaluated</strong> on their knowledge of the skeletal system and its functions by creating a PowerPoint presentation to present and explain the skeletal system’s role in the five senses to the class and teacher.</p>

      <br>

      <h2>Lesson Plan – 5-E Model with Guiding Questions</h2>

	    <br>

      <h3>Guiding Questions: What are the guiding questions for this lesson?</h3>

      <ol>
        <li><strong>What functions do the skeletal system provide for the human body?</strong> The skeletal system provides structure for the body by giving it shape and supporting the weight of muscles and organs. The skeletal system along with the nervous and muscular systems allow the body to move through the relationship between each system. The most important function of the skeletal system is to provide protection of vital organs. The skeletal system functions to provide red and white blood cell production, while also providing an area for calcium and phosphorous storage.</li>

        <li><strong>What is the general function of the skeletal system?</strong> The skeletal system creates a frame for our bodies. It gives the body shape, supports all of our tissues and protects our vital organs.</li>

        <li><strong>What bones function with the five senses and how do they help each sense?</strong> The sense of taste derives from the tongue, which is aided by the hyoid bone to allow movement and help in swallowing. The human body has the ability to touch through the largest organ of the body, the skin. The skin has the ability to take shape from the human skeleton, so the bones play a vital role in shape and protection for the sense of touch. For example, the distal phalanges help provide protection through the fingernails that for the fingertips. The bones of the cranium protect and support the sensory organs responsible for sight, hearing, smell, and taste. The hyoid bone supports and aids the movement of the tongue, the three ossicles or ear bones aid in hearing, the nasal and nasal concha help force air through the nasal passages, and the zygomatic, palatine, maxilla, and frontal bones form the eye socket.</li>
      </ol>

      <br>

      <h3>Teaching Phase: How can teacher present the concept or skill to students?</h3>

      <ol>
        <li>The teacher leads a discussion about the functions and parts of the skeletal system during the "engage" part of the lesson.</li>

        <li>The teacher uses the projector to demonstrate to the class the Explore 3D model of a human skeleton and its parts with questions from students, during the "engage" part of the lesson.</li>

        <li>The teacher gives the students an opportunity to explore the 3D model of the human skeleton to read associated bone facts and interact with the different areas of the bones to understand functions of the skeletal system. The teacher circulates and point out functions of different bones and ask leading questions to help the students relate what they are seeing to the structure of the skeletal system, during the "explore" part of the lesson.</li>

        <li>The teacher discusses and clarifies the information contained in the video clip about the functions of the skeletal system, during the "explore" part of the lesson.</li>

        <li>The teacher then leads a class discussion about "What functions do the skeletal system provide for the human body?" and show/model for students how to write a summary in their notebooks, during the "explain" part of the lesson.</li>

        <li>The teacher reads aloud "Why Do We Have Bones?" to the class and lead a discussion about the skeletal system and demonstrate how to do the "Build a Skeleton" during the "elaborate" part of the lesson.</li>
      </ol>

      <br>

      <h3>Guided Practice: What activities or exercises do the students complete with teacher guidance?</h3>
    </section>

    <section class="engage" id="engage">
      <h2>ENGAGE:</h2>
      <p>Students realize prior knowledge of the human skeleton and identify areas that they may need to target during their studies. To create a human skeleton and label bones from previous knowledge, students can use the handout (<a href="http://eskeletons.org/resources" target="_blank">http://eskeletons.org/resources</a>) or students may create their own human skeleton. While the students are creating their skeletons have them list the functions of the skeletal system and what bones may be associated with those functions.</p>

      <ol>
        <li>In groups of 2 or 3, students create the large skeleton from the following link: <a href="http://eskeletons.org/resources" target="_blank">http://eskeletons.org/resources</a>  (Under Activities/Teaching Resources, click on the drop down for Life Size Printouts, click on Adult Homo Sapiens printer friendly to access the skeletal document).</li>

        <li>The teacher can have the students create their skeletons as a class, the teacher can build one skeleton and project it with a document camera for easy viewing, or each student can create their own depending on time. Students can label the skeletal parts on their diagrams if the teacher chooses. Optional activity is to have the students draw and create their own skeleton from background knowledge, and then check their work with the STARS: Anatomy app during the Explore section.</li>

        <li>Students read the following passages: <a href="https://courses.lumenlearning.com/ap1/chapter/the-functions-of-the-skeletal-system/" target="_blank">https://courses.lumenlearning.com/ap1/chapter/the-functions-of-the-skeletal-system/</a> and <a href="https://www.livestrong.com/article/115165-functions-skeleton/" target="_blank">https://www.livestrong.com/article/115165-functions-skeleton/</a>. Then students summarize the functions of the human skeleton from their previous list of the skeletal system functions to compare their correct and incorrect observations for the functions of the skeletal system.</li>
      </ol>
    </section>

    <section class="explore" id="explore">
      <h2>EXPLORE:</h2>

      <p>During this lesson, students use the STARS: Anatomy app to apply their previous knowledge of the human skeleton system as they begin to better understand the functions of the skeletal system.</p>

      <p>Materials: each student needs a device (tablet, computer, smart phone, etc.) to access the app, which is available through WebGL, IOS, and Android (downloadable through Web Player, Apple’s App Store, and the Google Play store), the STARS: Anatomy app, paper/notebook, and pencil/pen.</p>

      <h3>Guiding Question for the Explore Section</h3>

      <p><strong>What functions do the skeletal system provide for the human body?</strong> The skeletal system provides structure for the body by giving it shape and supporting the weight of muscles and organs. The skeletal system along with the nervous and muscular systems allow the body to move through the relationship between each system. The most important function of the skeletal system is to provide protection of vital organs. The skeletal system functions to provide red and white blood cell production, while also providing an area for calcium and phosphorous storage.</p>

      <ol>
        <li>The students open the Explore 3D model of the skeleton in the Stars Anatomy app from a device and make observations about the bones they placed correctly and incorrectly on their created skeletons. Have the students draw a skeleton from the Explore 3D model making note of the bones incorrectly labeled.</li>

        <li>Students work in pairs or small groups to examine bones in the 3D model of the skeletal system. The students look at the bones for evidence of the structures and components that may have been part of skeletal system functions. The students infer about the purpose the bone served based on its physical appearance. Encourage them to pay attention to specific bones and how they serve the body in the different functions of the skeletal system.</li>

        <li>Gauge student’s ability to identify bones with the <strong><em>Bone Hunter</em></strong> area in the <em>STARS Anatomy</em> app. Students challenge each other to get the top Bone Streak in the <strong><em>Bone Hunter</em></strong>. This is a great way to quiz how well students know the bones by name and location on the skeleton. Students are given the name of a bone and they are required to select the bone on a 3D model of the skeleton. By default the bone to locate is any bone in the skeletal system, but the areas can be more specific to include one or multiple areas of the following: Arms, Hands, Legs, Feet, Cranium, or Torso.</li>

        <li>Guide students into researching about how the skeletal system is involved in the five senses of the human body. Allow students to start formulating how the bones play a role in the five senses. Point out the grouping of bones and ask leading questions about where and how each bone serves a purpose.</li>
      </ol>

      <br>

      <p>The students then watch the short video clip about functions of the skeletal system. Video Clip - <a href="https://www.youtube.com/watch?v=QJwORUokEXc" target="_blank">https://www.youtube.com/watch?v=QJwORUokEXc</a></p>
    </section>

    <section class="explain" id="explain">
      <h2>EXPLAIN:</h2>

      <p>During this lesson, the teacher leads a discussion about <strong>"What functions do the skeletal system provide for the human body?"</strong> and students write a summary in their notebooks or on paper.</p>

      <p>Students can review the two articles (https://courses.lumenlearning.com/ap1/chapter/the-functions-of-the-skeletal-system/ and <a href="https://www.livestrong.com/article/115165-functions-skeleton/" target="_blank">https://www.livestrong.com/article/115165-functions-skeleton/</a>) and view the video clip (<a href="https://www.youtube.com/watch?v=QJwORUokEXc" target="_blank">https://www.youtube.com/watch?v=QJwORUokEXc</a>) to help them summarize the functions of the skeletal system. The students must write a summary in their own words and relate how their own body functions with the help of their skeletal system.</p>
    </section>

    <section class="elaborate" id="elaborate">
      <h2>ELABORATE:</h2>

      <p>Students play the Quest feature on the STARS: Anatomy app. First, the teacher can have the students play the first couple of levels together to collect the bones. Then students can work in pairs to compete against each other, or they can complete the game independently and try to get the highest score in the classroom. After some time playing the game, the students proceed to the Challenge section of the STARS: Anatomy app to test their knowledge of the skeletal system. The students can assess how much they know about the skeletal system by taking the quizzes in each area of the Torso, Arms, Legs, and Cranium. To challenge the students even further, the students can attempt the Master Challenge of the quizzes to try all the most difficult questions.</p>
    </section>

    <section class="evaluate" id="evaluate">
      <h2>EVALUATE:</h2>

      <p>In groups of 2 or 3, students create a PowerPoint presentation discussing how the skeletal system provides one of the five functions for the human body. The students research further how bones aid in the five senses and present to the class on how the body completes each task of sense.</p>

      <h3>Guiding Question for the Evaluate Section</h3>

      <p><strong>What bones function with the five senses and how do they help each sense?</strong> The sense of taste derives from the tongue, which is aided by the hyoid bone to allow movement and help in swallowing. The human body has the ability to touch through the largest organ of the body, the skin. The skin has the ability to take shape from the human skeleton, so the bones play a vital role in shape and protection for the sense of touch. For example, the distal phalanges help provide support and movement for the fingertips. The bones of the cranium protect and support the sensory organs responsible for sight, hearing, smell, and taste. The hyoid bone supports and aids the movement of the tongue. The three ossicles or ear bones aid in hearing. The nasal and nasal concha help force air through the nasal passages, and the zygomatic, palatine, maxilla, and frontal bones form the eye socket.</p>

      <h3>Independent Practice: What activities or exercises do students complete to reinforce the concepts and skills developed in the lesson?</h3>

      <p>The students make an illustration of the skeleton and its parts, write a summary on the functions of the skeletal system, do a hands-on investigation of the 3D model of the skeleton from the STARS: Anatomy app, complete a virtual lab to build a skeleton, and for their final task make a PowerPoint presentation on how the bones aid in the five senses.</p>

      <h3>Closure: How can the teacher assist students in organizing the knowledge gained in the lesson?</h3>

      <ol>
        <li>The teacher models how to draw a skeleton and identify the parts and provide feedback.</li>

        <li>The teacher reviews summarizing and writing in their notebook and provide feedback.</li>

        <li>The teacher shows the students how to make a PowerPoint presentation and review the accompanying rubric <a href="/assets/docs/PresentationRubric.xlsx" target="_blank">(Download Rubric)</a>.</li>
      </ol>

      <br>

      <h3>Summative Assessment</h3>

      <ol>
        <li><strong>Skeletal System Drawing (Evaluate Section)</strong> - The teacher reviews/grades the student-created skeletal drawings from their notebooks. Students may self-assess using the 3D skeleton model from the STARS: Anatomy app or the teacher can assess the students.</li>

        <li><strong>Presentation about the skeletal systems role in the five senses (Evaluate Section)</strong> - The teacher allows students to research further about the five senses on how the role of the skeletal system aids in each sense. The students identify the bones that are related to each of the senses and then elaborate further the role the bones play in the ability of the body to provide that specific sense.</li>
      </ol>

      <br>

      <h3>Formative Assessment</h3>

      <p>Formative Assessment options:</p>

      <ol>
        <li><strong>Skeleton Illustration in Notebook (Engage Section):</strong> The teacher reviews with the students the skeletal drawings from their notebooks and discusses the parts and functions and clears up any misconceptions.</li>

        <li><strong>Summary about Bones function (Explain Section)</strong> - The teacher conducts a discussion about <strong>"What functions do the skeletal system provide for the human body?"</strong> from their notebooks.</li>
      </ol>

      <br>

      <p>Feedback to Students</p>

      <ul>
        <li>The teacher directs a whole group discussion about labeling the parts of the skeleton and going over the illustrations the students made in their notebooks and pointing out areas that need to be improved upon and clarifying any misconceptions they may have.</li>

        <li>The teacher leads a class discussion about <strong>"What functions do the skeletal system provide for the human body?"</strong> using guiding questions and leading the students to the correct answers. The teacher goes over the illustrations with the students in their notebooks and points out areas that need to be improved upon and clarify any misconceptions they may have.</li>

        <li>The teacher evaluates each student's PowerPoint using a rubric  <a href="/assets/docs/PresentationRubric.xlsx" target="_blank">(Download Rubric)</a> to go over it with the students after presentation and pointing out areas that need to be improved upon and clarifying any misconceptions they may have.</li>
      </ul>
    </section>

    <section class="accommodations" id="accommodations">
      <h2>ACCOMMODATIONS &amp; RECOMMENDATIONS</h2>

      <h3>Accommodations:</h3>

      <ul>
        <li>For those students that may need it, you could print the skeleton for them to assemble in their notebooks, instead of drawing.</li>

        <li>Students with reading difficulties may work in groups/pairs to go through the note taking worksheet together.</li>

        <li>Hard copies of the reading passage could also be created for re-reading by those students that are second language students or have reading difficulties.</li>
      </ul>

      <br>

      <h3>Additional Resources:</h3>

      <p>Video clip for functions of the skeletal system - <a href="https://www.youtube.com/watch?v=QJwORUokEXc" target="_blank">https://www.youtube.com/watch?v=QJwORUokEXc</a></p>

      <p>Articles to read - <a href="https://www.livestrong.com/article/115165-functions-skeleton/" target="_blank">https://www.livestrong.com/article/115165-functions-skeleton/</a> and <a href="https://courses.lumenlearning.com/ap1/chapter/the-functions-of-the-skeletal-system" target="_bkank">https://courses.lumenlearning.com/ap1/chapter/the-functions-of-the-skeletal-system</a></p>
    </section>
  </article>
</div>
