import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, Event } from '@angular/router';

@Component({
  selector: 'stars-rowan-bean-dialog',
  templateUrl: './rowan-bean-dialog.component.html',
  styleUrls: ['./rowan-bean-dialog.component.scss']
})
export class RowanBeanDialogComponent implements OnInit, OnDestroy {
  constructor(
    public rowanBeanDialogRef: MatDialogRef<RowanBeanDialogComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      this.rowanBeanDialogRef.close();
    });
  }

  ngOnDestroy(): void {
    if (this.rowanBeanDialogRef && this.rowanBeanDialogRef.close) {
      this.rowanBeanDialogRef.close();
    }
  }
}
