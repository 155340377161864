<stars-anatomy-overview></stars-anatomy-overview>

<div class="content">

	<stars-anatomy-features></stars-anatomy-features>
	<stars-anatomy-characters></stars-anatomy-characters>
	<stars-anatomy-platforms></stars-anatomy-platforms>

</div>

<stars-guest-teacher-resources></stars-guest-teacher-resources>
