import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnInit
} from '@angular/core';

import { Router, NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'stars-menu',
  templateUrl: './menu.html',
  styleUrls: ['./menu.scss']
})
export class MenuComponent implements OnInit {
  @HostBinding('class.open')
  @Input()
  open = true;
  menuItemDropdown = false;
  route: string;

  @Output() toggle = new EventEmitter<any>();

  constructor(private router: Router) {}

  // This toggles the "Apps" sub-menu on mobile click. Hover is handled in the scss.
  toggleMenuItemDropdown(): void {
    this.menuItemDropdown = !this.menuItemDropdown;
  }

  ngOnInit(): void {
    // Activates the "Apps" dropdown button border if the route contains '/apps'
    this.route = this.router.url;
    const dropdownButton =
      document.getElementsByClassName('dropdown-button')[0];
    this.router.events.subscribe((event: Event) => {
      this.menuItemDropdown = false;
      if (event instanceof NavigationEnd) {
        event.url.includes('/apps/')
          ? dropdownButton.classList.add('active')
          : dropdownButton.classList.remove('active');
      }
    });
  }
}
