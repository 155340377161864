import { Action } from '@ngrx/store';

export const TOGGLE = '[Menu] Toggle';

export class ToggleAction implements Action {
  readonly type = TOGGLE;

  constructor(public payload?: string) {}
}

export type Actions = ToggleAction;
