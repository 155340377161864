<div class="characters">
  <img src="assets/img/anatomy/orange_swooshed.png" class="orange" alt="Character">
  <img src="assets/img/anatomy/red_swooshed.png" class="red" alt="Character">
  <img src="assets/img/anatomy/blue_swooshed.png" class="blue" alt="Character">
</div>

<div class="inner">
  <img src="../../../assets/img/anatomy/stars-anatomy-logo.png" alt="U.S. Army STARS Anatomy">

  <div class="store-buttons">
    <a href="https://apps.apple.com/us/app/stars-anatomy/id1458763783" target="_blank"><img
        src="/assets/img/apple-store-badge.svg" alt="Apple Store"></a>
    <a href="https://play.google.com/store/apps/details?id=com.ArmyGameStudio.STARS_Anatomy" target="_blank"><img
        src="/assets/img/google-play-badge.svg" alt="Google Play Store"></a>
    <a href="https://anatomy.usarmystars.com/" target="_blank"><img src="/assets/img/web-player.svg" alt="Web Player"></a>
  </div>
</div>

<div class="transition"></div>
