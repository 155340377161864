<h2>Built on flexible platforms for any classroom</h2>

<p>Whether working on desktop or notebook computers, tablets or phones, iOS or Android, STARS: <em>Elements</em> will run on almost any platform so that students can learn inside and outside of the classroom.</p>

<img class="devices" src="/assets/img/devices.svg" alt="Devices Icon">

<h3>Choose your platform:</h3>

<div class="store-buttons">
  <a href="https://itunes.apple.com/us/app/u.s.-army-stars-elements-fun/id1103901837?ls=1&mt=8" target="_blank" class="app-store-button"><img src="/assets/img/apple-store-badge.svg" alt="Apple Store"></a>
  <a href="https://play.google.com/store/apps/details?id=com.agsgamestudio.stars" target="_blank" class="app-store-button"><img src="/assets/img/google-play.svg" alt="Google Play Store"></a>
  <a href="https://elements.usarmystars.com/" target="_blank" class="app-store-button"><img src="/assets/img/web-player.svg" alt="Web Player"></a>
</div>
