import { Component } from '@angular/core';
import { ElementsCharacterModalComponent } from './elements-character-modal/elements-character-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'stars-elements-characters',
  templateUrl: './elements-characters.html',
  styleUrls: ['./elements-characters.scss']
})
export class ElementsCharactersComponent {
  constructor(public dialog: MatDialog) {}

  characters: any = [
    {
      name: 'Non Metals',
      image: '/assets/img/elements/elements-characters/nonmetals.png'
    },
    {
      name: 'Transition Metals',
      image: '/assets/img/elements/elements-characters/transitionmetals.png'
    },
    {
      name: 'Actinoids',
      image: '/assets/img/elements/elements-characters/actinoids.jpg'
    },
    {
      name: 'Post Transition Metal',
      image: '/assets/img/elements/elements-characters/posttransitionmetals.jpg'
    },
    {
      name: 'Earth Metals',
      image: '/assets/img/elements/elements-characters/earthmover.jpg'
    },
    {
      name: 'Noble Gases',
      image: '/assets/img/elements/elements-characters/noblegases.jpg'
    },
    {
      name: 'Lathanoid',
      image: '/assets/img/elements/elements-characters/lanthanum.jpg'
    },
    {
      name: 'Alkali Metals',
      image: '/assets/img/elements/elements-characters/alkalimetals.jpg'
    },
    {
      name: 'Halogens',
      image: '/assets/img/elements/elements-characters/halogens.jpg'
    },
    {
      name: 'Metalloids',
      image: '/assets/img/elements/elements-characters/metalloids.jpg'
    }
  ];

  launchCharacterModal(name: string, image: string): void {
    this.dialog.open(ElementsCharacterModalComponent, {
      height: '100vh',
      width: '100vw',
      panelClass: 'element-char-modal',
      data: {
        name: name,
        image: image
      }
    });
  }
}
