<div class="content">
  <aside>
    <h3>Lesson Plan By Category:</h3>

    <section class="category">
      <h4>Chemistry</h4>
      <a class="active" (click)="scrollToSection('lessons-area1')">Discover Repeating Patterns and Properties of Elements</a>

      <ul>
        <li>
          <a class="" (click)="scrollToSection('expectations')">Expectations</a>
        </li>

        <li>
          <a class="" (click)="scrollToSection('background-information')">Background Information</a>
        </li>

        <li>
          <a class="" (click)="scrollToSection('engage')">Engage</a>
        </li>

        <li>
          <a class="" (click)="scrollToSection('explore')">Explore</a>
        </li>

        <li>
          <a class="" (click)="scrollToSection('explain')">Explain</a>
        </li>

        <li>
          <a class="" (click)="scrollToSection('elaborate')">Elaborate</a>
        </li>

        <li>
          <a class="" (click)="scrollToSection('evaluate')">Evaluate</a>
        </li>
      </ul>
    </section>
  </aside>

  <article class="lessons-area" id="lessons-area1">
    <header>
      <h3>Lesson Description:</h3>

      <h1>Challenge students to discover repeating patterns and properties of the elements.</h1>
      <a class="button" href="/assets/docs/stars-lesson.pdf" target="_blank">Download Lesson</a>
      <a class="button poster" href="/assets/img/stars-periodic-table-poster.jpg" download>Download Poster</a>
    </header>

    <section class="expectations" id="expectations">
      <h3>Performance Expectation(s):</h3>

      <p>HS-PS1-1. Use the periodic table as a model to predict the relative properties of elements based on the patterns of electrons in the outermost energy level of atoms.</p>

      <h3>Specific Learning Outcomes:</h3>

      <ol>
        <li>Students will identify and describe the organization and components of the periodic table.</li>

        <li>Students will determine relationships between chemical and physical properties of elemental families in the periodic table.</li>

        <li>Students will manipulate protons, neutrons, and electrons to understand the composition and atomic symbols of each element on the periodic table.</li>

        <li>Students will understand and apply the octet rule to create stable chemical compounds.</li>
      </ol>
    </section>

    <section class="background-information" id="background-information">
      <h2>Narrative Background Information</h2>

      <h3>Prior Student Knowledge:</h3>

      <p>Familiarity with the Bohr model. Realization that the model of the atom is used to explain observable properties of elements and compounds. Physical changes. Elements, compounds, and mixtures.</p>

      <p><u>Remind students of the following background explanations during the Opening Activity:</u><br>
      Both physical and chemical changes are occurring when a candle is lit. Candle wax is essentially a lot of carbon and hydrogen atoms bonded together as molecules called hydrocarbons. These atoms and molecules are vibrating, and they are attracted to one another as a solid. These attractions between the molecules are called intermolecular forces. When the solid wax is heated, the molecules move faster and faster, and the intermolecular forces begin to break. Eventually, the solid melts to form liquid wax. The liquid wax moves up through the wick in a process called capillary action. Then, the liquid wax around the wick is converted into a gas through a process called vaporization. Melting, capillary action, and vaporization are all examples of physical changes because the atoms of the molecules do not rearrange; the molecules simply separate from one another.</p>

      <p>Burning is a chemical change. Burning happens when heat energy breaks apart the bonds between the atoms of the vaporized wax and the atoms of the oxygen gas from the air. When enough oxygen (O2) is present, the atoms rearrange to form the more stable compounds, carbon dioxide (CO2) and water (H2O). This reaction is called a combustion reaction. Combustion occurs when a substance reacts with oxygen to produce heat and light. Combustion is an exothermic reaction, a chemical reaction that releases heat energy. When there isn’t enough oxygen present for the reaction, incomplete combustion occurs. Incomplete combustion produces poisonous carbon monoxide gas (CO) and a black mixture of carbon particles called soot. When we blow out a candle’s flame, we blow away vaporized candle wax while removing the heat from the flame. A red ember remains, but it is not hot enough to re-ignite the remaining vaporized candle wax.</p>

      <h3>Science &amp; Engineering Practices:</h3>

      <p>Use a model to predict the relationship between systems or between components of a system.</p>

      <h3>Disciplinary Core Ideas:</h3>

      <h4>PS1.A: Structure and Properties of Matter</h4>

      <ol>
        <li>Each atom has a charged substructure consisting of a nucleus, which is made of protons and neutrons, surrounded by electrons.</li>

        <li>The periodic table orders elements horizontally by the number of protons in the atom’s nucleus and places those with similar chemical properties in columns. The repeating patterns of this table reflect patterns of outer electron states.</li>
      </ol>

      <h3>Crosscutting Concepts:</h3>

      <h4>Patterns</h4>

      <p>Different patterns may be observed at each of the scales at which a system is studied and can provide evidence for causality in explanations of phenomena.</p>

      <h3>Possible Preconceptions / Misconceptions: <span>(according to AAAS website)</span></h3>

      <ul>
        <li>AMM032: Atoms or molecules of a solid are not moving. (42%, 9-12)</li>

        <li>SCM038: Chemical reactions involve two reactants. (58%, 9-12)</li>

        <li>SCM070: A chemical reaction always happens when two substances are combined together. (16%, 9-12)</li>

        <li>SCM021: After a chemical reaction, the product is a mixture in which the old substances persist, and is not a new substance. (13%, 9-12)</li>
      </ul>
    </section>

    <section class="engage" id="engage">
      <h2>Lesson Plan - 5-E Model</h2>

      <h3>ENGAGE:</h3>

      <h4>Opening Activity - Access Prior Learning / Stimulate Interest / Generate Questions:</h4>

      <p>Students will observe a chemical reaction. The teacher will light a trick “relighting candle” over a bowl of sand or table salt. After blowing out the candle, the candle surprisingly re-lights itself after a few seconds. The bowl helps keep flying sparks away from flammable objects. Ask students to make careful observations as the trick candle is extinguished and re-lights again and again. Compare this to a regular candle. Whole group discussion can be incorporated while students make predictions about how both candles work. To finally extinguish the trick candle, place it upside down in the sand or salt, and leave it there for an hour before placing it near other objects that may be flammable.</p>

      <p><strong>Discuss:</strong> Trick candles contain tiny amounts of a pyrophoric (pī rōˈfôr ik) metal carefully placed inside the wick. A pyrophoric metal ignites spontaneously with the oxygen in the air at relatively low temperatures. The pyrophoric metal powder inside the wick of a trick candle is ignited by the small, red ember of an extinguished candle flame. Remember, the ember itself doesn’t provide enough energy to ignite the wax vapor, but it does provide enough energy to ignite a pyrophoric metal. When the pyrophoric metal reacts with the oxygen from the air, a large amount of light and heat is released in an exothermic reaction. You may have observed evidence of this reaction as tiny sparks flying from the trick candle. These sparks provide enough energy to reignite the vaporized candle wax, and the flame returns. Care must be taken to keep the sparks away from combustible material. In case of a fire, smother the flame, and follow basic fire safety guidelines.</p>

      <p>Special Class D fire extinguishers are used to extinguish fires from larger amounts of burning pyrophoric metal. Many pyrophoric metals react vigorously with water, so adding water will only make the fire worse. For this reason, fires of pyrophoric metals can be difficult to contain. Class D fire extinguishers use a dry powder of a stable substance such as sodium chloride, NaCl (table salt) or sodium carbonate, Na <sub>2</sub>CO <sub>3</sub>(washing soda). The powder smothers the flame by keeping oxygen (O <sub>2</sub>) away from it.</p>

      <p>Trick candles can be safely extinguished by burying them in salt, sand, or even water for at least an hour. People will naturally place relighting candles in water, so it would be a hazard if the metal inside the candle reacted explosively with water. The pyrophoric metal chosen for a trick candle should not react with water to a significant extent. <strong>Safety reminder: never place a trick candle in a trashcan or around other potentially-flammable objects until you are absolutely certain the flame will not return.</strong></p>

      <h4>Ask students:</h4>

      <ul>
        <li><strong>Question 1:</strong> What pyrophoric metal to you think is inside the trick candles that you saw today?</li>

        <li><strong>Question 2:</strong> What compound does the pyrophoric metal form when it reacts with oxygen in the air?</li>
      </ul>
    </section>

    <section class="explore" id="explore">
	  <h3>EXPLORE:</h3>

	  <h4>Lesson Description - Materials Needed / Probing or Clarifying Questions:</h4>

      <p>During this lesson, students will use the STARS: <em>Elements</em> app to apply their previous knowledge of the periodic table as they begin to discover the meaning and application of the octet rule.</p>

      <p><strong>Materials:</strong> each student will need a device (tablet, computer, smart phone, etc.) to access the app, which will be available through WebGL, iOS, and Android (downloadable through WebPlayer, Apple’s App Store, and the Google Play store), the STARS: <em>Elements</em> app, paper, brown paper bag, and pencil/pen/marker.</p>

      <p><strong>Part 1. Periodic Table.</strong> As an introduction to the STARS app, students should be given a few minutes to explore the Periodic Table within the app. Encourage students to click through different elements and compare the arrangement and information provided against other periodic tables in the room. While students are exploring the app, distribute the “STARS: <em>Elements</em>” student handout. Walk around the room to help students open and begin exploring the app. From the information in the handout, students should be able to realize that the alkali metals and Ca, Sr, and Ba would not make good pyrophoric metals for trick candles because they are too reactive in water to be practical. By process of elimination, students may be able to determine the correct answer. After everyone has had a chance to make a guess, check for understanding. Encourage students to explore the app before beginning part 2.</p>

      <blockquote>
        <h4>ANSWER:</h4>
        <strong>(Magnesium, Mg)</strong>

        <p>The most common pyrophoric metal used in trick candles is the element magnesium, Mg. Magnesium metal reacts with oxygen gas to form the ionic compound magnesium oxide, MgO in an exothermic reaction. The compound magnesium oxide is a white powder that has completely different properties than magnesium metal and oxygen gas. Magnesium oxide is not toxic, and it can even be used in medicine to help relieve indigestion. Magnesium oxide is made up of one Mg <sup>2+</sup>cation for every one O <sup>2-</sup>anion. This is a 1:1 ratio. Magnesium atoms are chemically stable as Mg <sup>2+</sup>, and oxygen atoms are stable as O <sup>2-</sup>. If the teacher has access and safety-training to ignite magnesium ribbon, this would be a good time to demonstrate the ignition of a small amount of magnesium metal.</p>
      </blockquote>

	  <br>

      <p class="part2"><strong>Part 2. Atom Builder.</strong> Students are encouraged to work in groups or pairs to use the Atom Builder. In the group, one student can do the Atom Builder while another opens the Periodic Table.</p>

      <p class="part2"><em>Note: The atomic model in this app is not to scale. The nucleus at the center of the atom is tiny in comparison to the space where electrons are found outside of the nucleus. The nucleus is enlarged to show that it is made up of protons and neutrons. For perspective, if a carbon atom were enlarged to the size of a football stadium, then the nucleus would be about the size of a small cherry seed. According to our current models of the atom based on experimental evidence, the nucleus is tiny, and the majority of the atom is just empty space where the electrons can be found.</em>
      </p>

      <p>Encourage the students to discover the atom builder and answer the questions on the handout.</p>

      <blockquote>
        <h4>ANSWERS:</h4>

        <ul>
          <li><span>12, 12, and 12.</span></li>

          <li><span>Protons and neutrons.</span></li>

          <li><span>Protons</span></li>

          <li><span>protons = positive (+); neutrons = neutral(o); electrons = negative (-)</span></li>

          <li><span>protons minus electrons</span></li>
        </ul>

        <br>

        <p><em>More information to help answer student questions:</em> <br>The relative atomic mass of magnesium is 24.31, as found on the periodic table. This number represents the ratio of the <strong>average mass</strong> of all of the isotopes in natural sample of magnesium to <sup>1</sup>&frasl; <sub>12</sub> <sup>th</sup>the mass of an atom of carbon-12. This number is not a whole number because there are many different types of magnesium isotopes. When taking a weighted average, the result is rarely a whole number.</p>

        <p>The mole is defined as the number of atoms in 12 grams of carbon-12. This number has been experimentally determined to be 6.02 x 10 <sup>23</sup>. This is a huge number, and the term “mole” is used to speak about 6.02 x 10 <sup>23</sup>units of anything, just like the term “dozen” is used to speak about 12 units of anything. Relative to a carbon-12 atom, a magnesium-24 atom is twice the mass, so the mass of one mole of magnesium-24 atoms is 24 grams. Due to the different isotopes of magnesium, a mole of naturally occurring magnesium atoms is a little more than twice the mass. A mole of naturally occurring magnesium has a mass of 24.31 grams. A gram is a unit for mass that can be measured using a balance.</p>

        <br>

        <ol class="alpha-list">
          <li><span>12 protons and 13 neutrons. 2. 2 valence electrons</span></li>

          <li><span>12 protons. 10 electrons. 8 valence electrons</span></li>

          <ul>
            <li><span>Either: 12, 13, or 14</span></li>

            <li><span>Either: <sup>24</sup>&frasl; <sub>12</sub>Mg <sup>2+</sup>, <sup>25</sup>&frasl; <sub>12</sub>Mg <sup>2+</sup>, or <sup>26</sup>&frasl; <sub>12</sub>Mg <sup>2+</sup></span></li>
          </ul>

          <li><span>2 electrons are lost</span></li>

          <li><span>8 protons, 10 electrons, 8 valence electrons</span></li>

          <ul>
            <li><span>Either: 8, 9, or 10</span></li>

            <li><span>Either: <sup>16</sup>&frasl; <sub>8</sub>O <sup>2-</sup>, <sup>17</sup>&frasl; <sub>8</sub>O <sup>2-</sup>, <sup>18</sup>&frasl; <sub>8</sub>O <sup>2-</sup></span></li>
          </ul>

          <li><span>2 electrons are gained</span></li>

		      <li><span>The valence electrons are transferred from the magnesium to the oxygen.</span></li>

          <li><span>Neon (10 electrons total and 8 valence electrons). Neon is a noble gas. This explains the stability of these ions according to current theory.</span></li>

          <li><span>Electrons are transferred from the magnesium to the oxygen. Each magnesium atom transfers two electrons to each oxygen atom.</span></li>
        </ol>
      </blockquote>

      <h4>Project:</h4>

      <ol class="alpha-list">
        <li><span>Preparation: Write the atomic numbers of the alkali metals, alkaline earth metals, and Ag, Zn, and Al on pieces of paper to put into a brown bag. Write the atomic number of the most electronegative non-metals in a second bag.</span></li>
        <li><span>Ask half of the class members to select and return an atomic number from bag 1 (the metals).</span></li>
        <li><span>Ask the other half of the class to select and return an atomic number from bag 2 (the nonmetals and noble gases).</span></li>
        <li><span>Returning papers to the bags will help make sure there is an equal number of metals and nonmetals in the classroom. If there is an odd number of students in the room, assign him a noble gas or assign yourself a number.</span></li>
        <li><span><u>Bag 1.</u> Atomic Numbers 3, 11, 19, 37, 55, 87, 4, 12, 20, 38, 56, 88, 47, 30, 13</span></li>
        <li><span><u>Bag 2.</u> Atomic Numbers 7, 8, 9, 15, 16, 17, 35, 53, 85</span></li>
      </ol>
    </section>

    <section class="explain" id="explain">
      <h3>EXPLAIN – Concepts Explained and Vocabulary Defined</h3>

      <p>After these activities, the teacher will introduce the noble gases and the octet rule. After the octet rule has been introduced, the teacher will discuss how we can use the number of valence electrons and the electronegativity values to predict how atoms will combine to create either ionic or covalent bonds. The teacher will then draw the Bohr model of various metal and nonmetal atoms as examples. As electron configurations are discussed, refer back to the Periodic Table app to help students use the periodic table to interpret correct electron configurations for each atom. Open the Periodic Table on the home screen on the app and explore together:</p>

      <ol class="alpha-list">
        <li><span>Neutral atoms of which elements have 8 valence electrons? (halogens, except helium only has 2 valence electrons)</span>
        </li>

        <li><span>How many valence electrons do neutral atoms of the alkali metals have? (1)</span>
        </li>

        <li><span>How many valence electrons do neutral atoms of the alkaline earth metals have? (2)</span>
        </li>

        <li><span>How many valence electrons do the halogens have? (7)</span>
        </li>

        <li><span>How many valence electrons does an atom of oxygen have? (6)</span>
        </li>
      </ol>

      <p>Next, the teacher will introduce the Lewis dot structure for atoms, showing only valence electrons around each chemical symbol. The teacher will then show how the Periodic Table helps us quickly determine the number of valence electrons in the main group elements (omitting the transition metals and rare earth metals) by simply looking at the group number for the element. The first group, the alkali metals, have 1 valence electron. The second group, the alkaline earth metals, have 2 valence electrons. The first group after the transition metals (boron’s group) has 3 valence electrons. The next group (carbon’s group) has 4 valence electrons. The next group (nitrogen’s group) has 5 valence electrons. Oxygen’s group has 6 valence electrons. The halogens have 7 valence electrons. The noble gases have 8 valence electrons (exception: helium is stable with only 2 valence electrons.) The valence electron theory explains predictable physical and chemical properties of the elements.</p>

      <h4>Vocabulary</h4>

      <ul>
        <li><span><strong>Isotopes:</strong> atoms of the same element (same number of protons) but different mass number (different number of neutrons)</span>
        </li>

        <li><span><strong>Valence Electrons:</strong> electrons in the outermost shell of an atom. These electrons are the furthest from the nucleus.</span>
        </li>

        <li>
          <span><strong>Ions:</strong> Atoms or group of atoms with a net positive or negative charge. The total number of electrons are not equal to the total number of protons.</span>

          <ul>
            <li><span><strong>Cation</strong> - A positively charged ion. Cations have more protons than electrons. A cation is formed when at atom loses one or more electrons.</span>
            </li>

            <li><span><strong>Anion</strong> - A negatively charged ion. Anions have more electrons than protons. An anion is formed when an atom gains one or more electrons.</span>
            </li>
          </ul>
        </li>

        <li><span><strong>Ionization energy:</strong> the energy required to remove the most loosely bound valence electron of an atom to form a cation. The first ionization energy is the energy required to lose an electron to form a 1+ cation. (The second ionization energy is the energy required to lose a second electron to form a 2+ cation.) The lower the ionization energy, the more likely the atom is to form a cation.</span>
        </li>

        <li><span><strong>Octet Rule:</strong> the tendency of atoms to bond together in such a way as to have 8 valence electrons, or the same number of valence electrons as a nearby noble gas.</span>
        </li>

        <li><span><strong>Electronegativity:</strong> measure of the tendency of an atom to attract a bonding pair of electrons. Atoms with the same or similar electronegativity values share electrons form covalent bonds. Atoms with electronegativity differences greater than 1.9 combine together to form ionic bonds. Nonmetals typically have much higher electronegativity values than metals.</span>
        </li>

        <li><span><strong>Ionic Bonds:</strong> the attraction between oppositely charged ions due to the <u>transfer</u> of valence electrons. Ionic bonds typically form between metals and nonmetals. Compounds held together by ionic bonds are called ionic compounds.</span>
        </li>

        <li><span><strong>Covalent Bonds:</strong> the attraction between atoms due to the <u>sharing</u> of one or more pairs of electrons. Covalent bonds typically form between nonmetals. Compounds held together by covalent bonds are called covalent compounds.</span>
        </li>

        <li><span><strong>Lewis dot structures:</strong> diagrams that show how the valence electrons are bonded between atoms. A single line represents two shared valence electrons. A double bond represents four shared electrons. A triple bond represents six shared valence electrons.</span>
        </li>
      </ul>
    </section>

    <section class="elaborate" id="elaborate">
      <h3 id="elaborate">ELABORATE – Applications and Extensions</h3>

      <h4>STARS app:</h4>

      <p><strong>Smashbond.</strong> Finally, students will play the Smashbond game. First, they will do a few rounds as a class for practice (see Teacher’s Guide for full explanation of how the game is played). Then, students can work in pairs to compete against each other, or they can complete the game independently and try to get the highest score in the classroom. In the game, students will apply their knowledge of the octet rule and bonding in order to combine atoms. The teacher will lead the first round of combinations to reinforce learning objectives, and the students can practice independently or compete in pairs or groups. The grid of tiles in the game represent an element symbol and the number of electrons in their valence shell, and students can drag elements to create 2 and 3 atom combinations.</p>
    </section>

    <section class="evaluate" id="evaluate">
      <h3>EVALUATE: Formative Monitoring (Questioning / Discussion):</h3>

      <ul>
        <li><span>19 protons</span>
        </li>

        <li><span>20 neutrons</span>
        </li>

        <li><span>18 electrons</span>
        </li>

        <li><span>53 protons</span>
        </li>

        <li><span>74 neutrons</span>
        </li>

        <li><span>54 electrons</span>
        </li>

        <li><span>12</span>
        </li>

        <li><span>2</span>
        </li>

        <li><span>1</span>
        </li>
      </ul>

      <p><span>Take all of the answers that they wrote, and discuss this question together as a class, discussing valence electrons, noble gas electron configurations, and the octet rule.</span></p>
    </section>

    <footer>
      <h3>Summative Assessment (Quiz / Project / Report):</h3>

      <p>The STARS app provides the STARS Challenge as an assessment of some of the content gained in this lesson. Students will use elements of matching, drag and drop, and multiple choice questions to complete the assessment and try to get 5 stars (the highest score). This can be used as an assessment in the classroom, added practice at home, or a source of extra credit as students can continue to complete the STARS Challenge until they get all 5 stars.</p>

      <h3>Elaborate Further / Reflect: Enrichment:</h3>

      <p>The STARS app can be used at home as continued practice, as well as continued review in the classroom. The Periodic Table can be the source for future references in unit on Chemistry, as well as the Atom Builder. Smashbond can be played both within the classroom and at home for students to continue learning by trying to beat their highest score. The STAR Challenge is a resource that can be used for a review or a future refresher of background information before a new unit is introduced. Each of these activities can be completed individually as an enrichment activity when specific needs require further practice.</p>
    </footer>
  </article>
</div>
