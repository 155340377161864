import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { State } from '../reducer';

import { TealiumUtagService } from '../tealium/utag.service';

import * as menu from '../actions/menu';

@Component({
  selector: 'stars-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss']
})
export class HeaderComponent implements OnInit {
  menuOpen$;

  constructor(
    private store: Store<State>,
    private tealium: TealiumUtagService
  ) {}

  ngOnInit(): void {
    this.menuOpen$ = this.store.select((s) => s.menu);
  }

  onToggleMenu() {
    this.store.dispatch(new menu.ToggleAction());
    const mobileMenuButton = document.getElementById('mobile-menu-button');
    if (this.menuOpen$) {
      mobileMenuButton.classList.toggle('close');
    }
  }

  tealiumLink(event: Event): void {
    this.tealium.link({
      tealium_event: 'outbound_clicks'
    });
  }
}
