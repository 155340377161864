<h2>Meet Our Tinysaurs</h2>

<div class="wrapper">
  <div class="character t-rex">
    <div class="gradient">
        <div class="shadow"></div>

        <img src="../../../assets/img/anatomy/red-tinysaur.png" alt="T-Rex">
    </div>

    <hr>

    <h3>Theodore Rex</h3>

    <p>Theodore Rex is a student intern of Professor Terri's who is eager to get into the action. While without any prominent special powers, T-Rex pummels straight through obstacles in the pursuit of knowledge.</p>
  </div>

  <div class="character triceratops">
    <div class="gradient">
        <div class="shadow"></div>

        <img src="../../../assets/img/anatomy/blue-tinysaur.png" alt="Triceratops">
    </div>

    <hr>

    <h3>Sarah Tops</h3>

    <p>Sarah Tops is a geologist who tackles problems with precision. Tops isn't the strongest tinysaur around, but makes up for it with a special charge ability that gives an extra forward burst of speed and force.</p>
  </div>

  <div class="character ankylosaurus">
    <div class="gradient">
        <div class="shadow"></div>

        <img src="../../../assets/img/anatomy/orange-tinysaur.png" alt="Ankylosaurus">
    </div>

    <hr>

    <h3>Anky Bean</h3>

    <p>Anky Bean is an engineer with a special power that makes sure the expedition keeps spinning forward - one rotation of that strong tail is enough to clear out obstacles and draw in interesting finds.</p>
  </div>

  <div class="character stegosaurus">
    <div class="gradient">
        <div class="shadow"></div>

        <img src="../../../assets/img/anatomy/green-tinysaur.png" alt="Stegosaurus">
    </div>

    <hr>

    <h3>Plato Stego</h3>

    <p>Plato Stego is a demolitions expert. Stego uses the trademark plates to stick to almost any surface and explode - a useful tactic for clearing out obstacles or displacing bones or objects for easier access.</p>
  </div>
</div>
