import { Action } from '@ngrx/store';
import * as menu from '../actions/menu';

export type state = boolean;

export function reducer(show: state = false, action: Action) {
  switch (action.type) {
    case menu.TOGGLE:
      return !show;
    default:
      return show;
  }
}
