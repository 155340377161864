<div class="wrapper">
  <h1>STARS: Anatomy is a free app designed to help students grasp the foundations of anatomy.</h1>

  <div class="image-row">
    <img src="/assets/img/anatomy/quest-screenshot.png" alt="Anatomy Quest">
  </div>

  <div class="inner">
    <h2 class="play-title">Quest</h2>

    <p>Join the Tinysaurs on a journey of discovery in our physics based puzzle game. Test your knowledge, find bones, and collect artifacts in the caves with 30+ levels.</p>
  </div>

  <div class="image-row">
      <img src="/assets/img/anatomy/explore-screenshot.png" alt="Anatomy Explore">
  </div>

  <div class="inner">
    <h2 class="explore-title">Explore</h2>

    <p>In Explore you have a powerful 3D modeled human skeleton. Use the search function to find a specific bone, or select what you see to learn the shape, functions, name, and pronunciation of any bone in the body. On a supported device you can also view the skeleton in Augmented Reality.</p>
  </div>

  <div class="image-row">
    <img src="/assets/img/anatomy/challenge-screenshot.png" alt="Anatomy Challenge">
  </div>

  <div class="inner">
      <h2 class="challenge-title">Challenge</h2>

      <p>Test your knowledge with quizzes in the STARS Challenge. Choose from subjects like Torso or Legs & Feet. When you’re ready to prove your mastery of the skeleton, step up to the all-inclusive Master Challenge.</p>
  </div>

  <div class="image-row">
    <img src="/assets/img/anatomy/bonehunter-screenshot.png" alt="Anatomy Bone Hunter">
  </div>

  <div class="inner">
    <h2 class="bonehunter-title">Bone Hunter</h2>

    <p>See how long of a streak you can build finding the correct bone. Test yourself on every bone in the body, or narrow it down to specific bone groups like the Hands, Legs, or Cranium. Also playable in Augmented Reality!</p>
  </div>
</div>
