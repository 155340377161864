
<div class="content">
  <h2>Terms &amp; Conditions</h2>
  <h3> 1.  ACCEPTANCE OF TERMS </h3>
  <p> This is an Agreement between you and the Department of the Army ("DA"), and governs your use of the web site located at usarmystars.com and the content, information, and services provided through the usarmystars.com web site (collectively the "Web Site"). Each time you use the Web Site you signify that you have read the terms, conditions and notices in the Web Site Terms of Use ("Agreement") and you agree to accept them, without limitation or qualification, and you represent and warrant that you have the legal authority to agree to and accept this Agreement on behalf of yourself and any person you purport to represent. </p>
  <h3> 2.    CHANGES TO THIS AGREEMENT </h3>
  <p> DA reserves the right to change, supplement or amend the Agreement at any time, for any reason, and without prior notice or liability to you. The most current version of the Agreement can be reviewed by clicking on the "Legal and Privacy" hypertext link located at the bottom of each usarmystars.com web page. </p>
  <h3> 3.    JURISDICTION </h3>
  <p> The Web Site is controlled and operated in whole or in part by DA from its offices within the United States. BY ACCESSING THE WEB SITE YOU AGREE THAT THE STATUTES AND LAWS OF THE UNITED STATES AND THE STATE OF VIRGINIA, U.S.A., WITHOUT REGARD TO CONFLICTS OF LAWS PRINCIPLES, WILL APPLY TO ALL MATTERS RELATING TO USE OF THE WEB SITE, AND YOU AGREE THAT ANY LITIGATION SHALL BE SUBJECT TO THE EXCLUSIVE JURISDICTION OF THE STATE OR FEDERAL COURTS IN VIRGINIA, U.S.A. Those who access the Web Site from other locations do so at their own risk and are responsible for compliance with applicable local laws. DA makes no representation that the text, photographs, graphics, software, video, and audio content (collectively the "Content") found in the Web Site is appropriate or available for use in other locations.  Access to the Web Site from territories where the Content is illegal is prohibited. </p>
  <h3> 4.    LINKS TO THIRD PARTY WEB SITES </h3>
  <p> The Web Site may contain links to other web sites operated by parties other than DA which are provided for convenience only.  A link to a non-DA web site does not mean DA endorses or accepts any responsibility for the Content, or the use, of such web sites. Furthermore, DA makes no representations or warranties as to any products made available at such web sites. </p>
  <h3> 5.    LINKING, FRAMING, MIRRORING, AND SCRAPING THE WEB SITE </h3>
  <p> The framing, mirroring, scraping or data mining of the Web Site or any of its Content in any form and by any method is strictly prohibited. </p>
  <p>Links to the Web Site not expressly authorized by this Agreement or without the express written permission of DA are strictly prohibited. To request permission to link to the Web Site, please submit your request to our <a  href="mailto:agsapps@armygamestudio.com?subject=Press%20Request%20from%20Stars%20Website" target="_blank">Press Support Team</a>. Use of "usarmystars.com" as a link to this Web Site is permitted, but shall not be construed as a waiver of any rights by DA. DA reserves the right to cancel and revoke any permission it may give to link to the Web Site at any time, for any reason, without any notice, and without any liability to you or any other person.</p>
  <h3> 6.    RESTRICTIONS ON USE OF CONTENT </h3>
  <p> The Web Site, its Contents, as well as the organization and layout of the Web Site are the property of DA or its affiliates and contains copyrighted material, trademarks, and other proprietary information protected by the laws of the United States, and international treaties.  Except as stated herein, none of the Content may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without the prior written permission of DA or the copyright owner. Additionally, you also may not mirror or distribute any part of this Web Site over any network, including a local area network, nor sell or offer it for sale. Any unauthorized use of any material contained on this Site may violate copyright laws, trademark laws, the laws of privacy and publicity, communications regulations and statutes, and other laws. </p>
  <p> Unless otherwise explicitly stated herein, nothing in this Agreement shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication, or otherwise. Permission is granted to display, copy, distribute and download the materials on this Web Site for personal, non-commercial use only, provided you do not modify the materials and that you retain all copyright, trademark, and other proprietary notices contained in the materials. This permission terminates automatically if you breach any of terms or conditions stated above. Upon termination, you must immediately destroy any downloaded and printed materials. </p>
  <p> The registered trademarks U.S. ARMY® with star design, and U.S. Army STARS (collectively the "Marks") are the property of DA.  All other trademarks are the property of their respective owners. Except as otherwise authorized by DA, you are not authorized to copy or reproduce any of the Marks.  To the extent you may be authorized to reproduce the Marks, you acknowledge DA's exclusive rights to its Marks and agree that any use of DA's Marks by you shall inure to DA's sole benefit. You agree not to incorporate any of DA's Marks into your trademarks, service marks, company names, Internet addresses, domain names, or any other similar designations, for use on or in connection with computer or Internet-related products, games, services or technologies.  DA has the right to withdraw at any time and for any reason all rights you may have been granted to reproduce DA's Marks.  You agree that upon receiving written notice from DA or its authorized representative, you will immediately cease all use of the Marks.  This specifically includes removing the DA marks from any website you may own or control. </p>
  <h3> 7.    TERMINATION OF THIS AGREEMENT AND THE WEB SITE </h3>
  <p> If you breach any provision of this Agreement, you may no longer use the Web Site. </p>
  <p> DA may, at any time and for any reason and in its discretion: (a) change, suspend or terminate, temporarily or permanently, the Web Site or any part of it; or (b) restrict, suspend or terminate (in whole or in part) your permission to access or use the Web Site; all without any notice or liability to you or any other person. If this Agreement or your permission to access or use the Web Site is terminated by you, or by a person you represent, or by DA, then this Agreement and all other then existing agreements between DA and any persons you represent will continue to apply and be binding upon you and any persons you represent, jointly and severally, regarding your prior access to and use of the Web Site, and anything connected with, relating to or arising therefrom. </p>
  <h3> 8.    COMPLETE AGREEMENT </h3>
  <p> This Agreement constitutes the entire Agreement between you and DA relating to your use of the Web Site, and supersedes all previous agreements, written, oral or otherwise, between you and DA with respect to your access and use of the Web Site. </p>
  <h3> 9.    SEVERABILITY </h3>
  <p> If any provision of this Agreement is held to be invalid by any law, rule, order or regulation of any government or by the final determination of any state or federal court, such invalidity shall not affect the validity or enforceability of any other provision in this Agreement </p>
  <h3> 10.   PRIVACY AND PROTECTION OF PERSONAL INFORMATION </h3>
  <p> See the <a href="/legal/privacy">Privacy Statement</a> disclosures relating to the collection and use of your information. </p>
  <h3> 11.   USER NAMES AND PASSWORDS </h3>
  <p> DA may in its sole discretion refuse to grant you a user name or password or any particular user name or password you may request. All user names and passwords remain the property of DA, and may be cancelled or suspended at any time by DA in its sole discretion and without any notice or liability to you or any other person.  You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify DA immediately of any unauthorized use of your account or any other breach of security. </p>
  <h3> 12.   NOTICE SPECIFIC TO SOFTWARE AVAILABLE ON THIS WEB SITE. </h3>
  <p> Any software that is made available to download from this Web Site or its affiliates ("Software") is the copyrighted work of DA and/or its suppliers. Use of the Software is governed by the terms of the end user license agreement which accompanies or is included with the Software ("License Agreement"). An end user will be unable to install any Software that is accompanied by or includes a License Agreement, unless he or she first agrees to the License Agreement terms. </p>
  <p> The Software is made available for download solely for use by end users according to the License Agreement. Any reproduction or redistribution of the Software not in accordance with the License Agreement is expressly prohibited by law, and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible. </p>
  <p> WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED, UNLESS SUCH REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PERMITTED BY THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE OR  EXPRESSLY CONSENTED TO BY DA. </p>
  <p> THE SOFTWARE IS WARRANTED ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT. </p>
  <h3> 13.   LIMITATION OF LIABILITY </h3>
  <p> YOU EXPRESSLY UNDERSTAND AND AGREE THAT: </p>
  <p> DA DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE, VALIDITY, ACCURACY, OR RELIABILITY OF, OR THE RESULTS OF THE USE OF, OR OTHERWISE RESPECTING, THE CONTENT OF THE WEB SITE OR ANY OTHER WEB SITES LINKED TO OR FROM THE WEB SITE. THE CONTENT OF THE WEB SITE IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT POSSIBLE PURSUANT TO APPLICABLE LAW, DA DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR OTHER VIOLATION OF RIGHTS. </p>
  <p> DA WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THE USE OR THE INABILITY TO USE THE WEB SITE, ITS CONTENT OR LINKS, INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES.  THIS DISCLAIMER SHALL APPLY EVEN IF DA KNEW OR SHOULD HAVE KNOWN THAT YOUR USE OF ITS WEB SITE WOULD CAUSE DAMAGE OR LOSS OF INFORMATION STORED ON YOUR COMPUTER. </p>
  <p> ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEB SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH.</p>
  <p> Any rights not expressly granted by this Agreement are reserved to DA. </p>
</div>
