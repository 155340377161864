import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, Event } from '@angular/router';

@Component({
  selector: 'stars-plato-mcstego-dialog',
  templateUrl: './plato-mcstego-dialog.component.html',
  styleUrls: ['./plato-mcstego-dialog.component.scss']
})
export class PlatoMcstegoDialogComponent implements OnInit, OnDestroy {
  constructor(
    public platoMcstegoDialogRef: MatDialogRef<PlatoMcstegoDialogComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      this.platoMcstegoDialogRef.close();
    });
  }

  ngOnDestroy(): void {
    if (this.platoMcstegoDialogRef && this.platoMcstegoDialogRef.close) {
      this.platoMcstegoDialogRef.close();
    }
  }
}
