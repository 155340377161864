<h2>Meet Our Characters</h2>
<p>Though all of the elements are unique, some of them share the same properties and are grouped together in the periodic table. These groups have been brought to life through characters that showcase all the shared attributes of each group from noble gases to transition metals to halogens. Explore each character to find out what makes each different, from its physical and chemical properties to reactivity.</p>

<div class="swiper-container">
  <div *ngFor="let character of characters" class="swiper-slide">
    <div class="slide-inner" [ngStyle]="{ 'background-image': 'url(' + character.image + ')' }" (click)="launchCharacterModal(character.name, character.image)">
      <h3>{{ character.name }}</h3>
    </div>
  </div>
</div>
