import { Routes } from '@angular/router';
import { GuestPageComponent } from './pages/guest.component';
import { AnatomyPageComponent } from './pages/anatomy';
import { ElementsPageComponent } from './pages/elements';
import { StemPageComponent } from './pages/stem';
import { LessonsPageComponent } from './pages/lessons';
import { GuidePageComponent } from './pages/guide';
import { TermsComponent } from './pages/terms';
import { PrivacyComponent } from './pages/privacy';
import { NotFoundPageComponent } from './pages/notfound';
import { ContactPageComponent } from './pages/contact';

export const routes: Routes = [
  { path: '', component: GuestPageComponent },
  { path: 'apps/anatomy', component: AnatomyPageComponent },
  { path: 'apps/elements', component: ElementsPageComponent },
  { path: 'stem', component: StemPageComponent },
  { path: 'lessons', component: LessonsPageComponent },
  { path: 'guides', component: GuidePageComponent },
  { path: 'legal/terms', component: TermsComponent },
  { path: 'legal/privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactPageComponent },
  { path: '**', component: NotFoundPageComponent }
];

export const routeComponents: any[] = [
  AnatomyPageComponent,
  ElementsPageComponent,
  StemPageComponent,
  LessonsPageComponent,
  GuidePageComponent,
  TermsComponent,
  PrivacyComponent,
  ContactPageComponent,
  NotFoundPageComponent
];
