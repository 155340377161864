<div class="content">
  <form class="contact-form" [formGroup]="contactForm" novalidate>
    <div class="divider"></div>
    <p class="form-title">Contact</p>
    
    <mat-form-field>
        <mat-label>Email</mat-label>
      <input matInput formControlName="emailAddress" id="emailAddress" type="email" placeholder="Enter your email">
      <mat-error *ngIf="hasError('emailAddress')">
        {{ getErrorMessage('emailAddress', ['required', 'email']) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Product</mat-label>
      <mat-select placeholder="Select your product" formControlName="product">
        <mat-option [value]="'Anatomy'">Anatomy</mat-option>
        <mat-option [value]="'Elements'">Elements</mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('product')">
        {{ getErrorMessage('product', ['required']) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" id="message" type="text" placeholder="What can we help you with?"></textarea>
      <mat-error *ngIf="hasError('message')">
        {{ getErrorMessage('message', ['required']) }}
      </mat-error>
    </mat-form-field>

    <div class="buttons">
      <button type="submit" class="form-button" (click)="onSubmit()">Submit</button>
    </div>
  </form>
</div>