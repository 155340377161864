import { Component } from '@angular/core';

import { TealiumUtagService } from '../../tealium/utag.service';

@Component({
  selector: 'stars-elements-overview',
  templateUrl: './elements-overview.html',
  styleUrls: ['./elements-overview.scss'],
  providers: [TealiumUtagService]
})
export class ElementsOverviewComponent {}
