<div class="transition"></div>

<h1>STARS: Elements is a free app designed to help students grasp the foundations of chemistry.</h1>

<div class="feature">
  <img src="/assets/img/periodic-table.png" alt="The Periodic Table Screenshot">
  <section>
    <h3>Interactive Periodic Table</h3>
    <p>The interactive Periodic Table presents each Element Name, Atomic Number, Atomic Radius, Ionization, Electron Affinity, and Electronegativity. The four data points show the four major trends on the Periodic Table.</p>
  </section>
</div>

<div class="feature">
  <img src="/assets/img/atom-builder.png" alt="Atom Builder Screenshot">
  <section>
    <h3>Atom Builder</h3>
    <p>Students can create elements in real time by adding and subtracting neutrons, electrons, and protons. The students discover the relationship between the composition of the elements through these subatomic particles.</p>
  </section>
</div>

<div class="feature">
  <img src="/assets/img/smashbond-board.png" alt="Smashbond Board Screenshot">
  <section>
    <h3>SmashBond</h3>
    <p>This matching style game is designed to help students learn the fundamentals of how elements bond to create compounds with rules based on the octet rule and Lewis dot structure.</p>
  </section>
</div>

<div class="feature last">
  <img src="/assets/img/stars-challenge.png" alt="Stars Challenge Screenshot">
  <section>
    <h3>Stars Challenge</h3>
    <p>A collection of challenges designed to evaluate and reinforce the core Chemistry principles of STARS. Including Octet Bonding, the structure and organization of the Periodic Table, key facts, and uses of elements.</p>
  </section>
</div>
