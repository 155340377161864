<div class="content">

  <stars-stem-slider></stars-stem-slider>

  <h1>Army STEM: Careers and Jobs</h1>
  <p>The demand for skilled workers in science, technology, engineering, and math (STEM) is rapidly growing. As technology enters even more aspects of daily life and the workplace changes, STEM skills and knowledge are becoming even more important in the workforce. Whether it’s in the civilian world or in the U.S. Army, STEM careers will put analytical thinking and problem-solving abilities to the test.</p>

  <section class="careers">
    <a class="career goArmyLink" *ngFor="let career of careers"
        [ngStyle]="{ 'background-image': 'url(' + career.image + ')' }"
        href="{{ career.careerLink }}" target="_blank" title="{{ career.fullJobTitle }}">
      <h2>{{ career.jobTitle }}</h2>
      <a class="pill-btn-small goArmyLink" href="{{ career.categoryLink }}" target="_blank">{{ career.category }}</a>
    </a>
  </section>
</div>
